import React, { useState, useEffect } from 'react'
import ResidentailGallery from './ResidentailGallery'

import modibaug from '../components/assets/MODIBAUG.jpg'

import aboli from '../components/assets/projects/residential/Aboli.webp'
import banali from '../components/assets/projects/residential/Banali.webp'
import bhupali from '../components/assets/projects/residential/Bhupali.webp'
import chaitrali from '../components/assets/projects/residential/Chaitrali.webp'
import gandhali from '../components/assets/projects/residential/Gandhali.webp'
import gitali from '../components/assets/projects/residential/Geetali.webp'
import himali from '../components/assets/projects/residential/Himali.webp'
import manali from '../components/assets/projects/residential/Manali.webp'
import meghali from '../components/assets/projects/residential/Meghali.webp'
import padmavilas from '../components/assets/projects/residential/Padmavilas.webp'
import palvi from '../components/assets/projects/residential/Palvi.webp'
import parnali from '../components/assets/projects/residential/Parnali.webp'
import priyali from '../components/assets/projects/residential/Priyali.webp'
import pushpali from '../components/assets/projects/residential/Pushpali.webp'
import rangali from '../components/assets/projects/residential/Rangali.webp'
import ratnali from '../components/assets/projects/residential/Ratnali.webp'
import rupali from '../components/assets/projects/residential/Rupali.webp'
import sankul from '../components/assets/projects/residential/Sankul.webp'
import sayali from '../components/assets/projects/residential/Sayali.webp'
import shamali from '../components/assets/projects/residential/Shamali.webp'
import shefali from '../components/assets/projects/residential/Shefali.webp'
import sonali from '../components/assets/projects/residential/Sonali.webp'
import sumali from '../components/assets/projects/residential/Sumali.webp'
import swapnali from '../components/assets/projects/residential/Swapnali.webp'
import Swarali from '../components/assets/projects/residential/Swarali.webp'
import vanali from '../components/assets/projects/residential/Vanali.webp'
import vrukshali from '../components/assets/projects/residential/Vrukshali.webp'
import Vrundali from '../components/assets/projects/residential/Vrundali.webp'

import DTC from '../components/assets/projects/commercial/DTC.webp';
import SumaCenter from '../components/assets/projects/commercial/SumaCentre.webp'
import SumaHouse from '../components/assets/projects/commercial/SumaHouse.jpeg';
import TacoHouse from '../components/assets/projects/commercial/TacoHouse.webp';
import Westend from '../components/assets/projects/commercial/Westend.webp';
//import Westend2 from '../components/assets/projects/commercial/Westend2.jpg';
import WestendCenter1 from '../components/assets/projects/commercial/WestendCenter1.webp';
import WestendCenter2 from './assets/WestendCenter2.webp';
import WestendCenter3 from './assets/WestendCenter3.webp';


const data = [
    { name: 'ABOLI', img: aboli, add: 'Law College Road' },
    { name: 'BANALI', img: banali, add: 'Karve Road' },
    // { name: 'BHUPALI', img: bhupali, add: 'Prabhat Road' },
    { name: 'CHAITRALI', img: chaitrali, add: 'Prabhat Road' },
    { name: 'GANDHALI', img: gandhali, add: 'Prabhat Road' },
    { name: 'GEETALI', img: gitali, add: 'Deccan Gym Khana' },
    { name: 'MANALI', img: manali, add: 'Prabhat Road' },
    // { name: 'MEGHALI', img: meghali, add: 'Prabhat Road' },
    { name: 'PADMAVILAS', img: padmavilas, add: 'Wanorie' },
    { name: 'PALVI', img: palvi, add: 'Prabhat Road' },
    // { name: 'PARNALI', img: parnali, add: 'Bhandarkar Road' },
    // { name: 'PRIYALI', img: priyali, add: 'Sakal Nagar' },
    // { name: 'PUSHPALI', img: pushpali, add: 'Apte Road' },
    // { name: 'RANGALI', img: rangali, add: 'Karve Road, Kothrud' },
    // { name: 'RATNALI', img: ratnali, add: 'BMCC Road' },
    // { name: 'RUPALI', img: rupali, add: 'Prabhat Road' },
    // { name: 'SANKUL', img: sankul, add: 'Erandwane' },
    { name: 'SAYALI', img: sayali, add: 'ICS Colony' },
    { name: 'SHAMALI', img: shamali, add: 'Prabhat Road' },
    { name: 'SHEFALI', img: shefali, add: 'ICS Colony' },
    { name: 'SONALI', img: sonali, add: 'Prabhat Road' },
    { name: 'SUMALI', img: sumali, add: 'Gulmohar Path' },
    { name: 'SWAPNALI', img: swapnali, add: 'Law College Road' },
    { name: 'SWARALI', img: Swarali, add: 'Law College Road' },
    { name: 'VANALI', img: vanali, add: 'Bhandarkar Road' },
    { name: 'VRUKSHALI', img: vrukshali, add: 'Prabhat Road' },
    { name: 'VRUNDALI', img: Vrundali, add: 'Law College Road' },
    { name: 'HIMALI', img: himali, add: 'Erandwane' },
]

const data2 = [
    { name: 'DTC', img: DTC, add: 'Erandwane' },
    { name: 'Suma Center', img: SumaCenter, add: 'Erandwane' },
    { name: 'Suma House', img: SumaHouse, add: 'Prabhat Road' },
    { name: 'Taco House', img: TacoHouse, add: 'Law College Road' },
    { name: 'Nexus Westend Mall', img: Westend, add: 'Aundh' },
    // { name: 'Nexus Westend Mall', img: Westend2, add: 'Aundh' },
    { name: 'Westend Center I', img: WestendCenter1, add: 'Aundh' },
    { name: 'Westend Center II', img: WestendCenter2, add: 'Aundh' },
    { name: 'Westend Center III', img: WestendCenter3, add: 'Aundh' }
];

const Gallery = () => {
    const [residential, setResidential] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const clkHnd = (value) => {
        if (value === 'residential') {
            setResidential(true)
        } else {
            setResidential(false)
        }
    }

    return (

        <div>
            <p className='text-3xl font-semibold text-black2 text-center mb-8 font-glacialBold'>Gallery</p>
            <div className='flex flex-row justify-center gap-8 bg-gray-100 mx-4 py-2'>
                <button onClick={() => { clkHnd('residential') }} className='bg-white text-blac shadow w-5/12 px-6 py-3 rounded-md meri'>Residential</button>
                <button onClick={() => { clkHnd('commercial') }} className='bg-white text-blac shadow w-5/12 px-6 py-3 rounded-md meri'>Commercial</button>
            </div>

            <p className='text-center text-2xl text-black2 font-semibold my-4 font-glacialBold'>{residential ? "Residential Projects" : "Commercial Projects"}</p>

            {residential == true && <div className='flex flex-row justify-center'>

                <div className='flex flex-row justify-center flex-wrap gap-4 mb-20'>
                    {data.map((value, index) => {
                        return <div key={index} className='flex flex-col rounded-xl shadow-xl border border-solid border-gray-300 my-4 w-5/12 md:w-3/12 '>
                            <img src={value.img} className='w-full h-64 rounded-xl'></img>

                            <div>
                                <p className='text-xl mt-2 mb-4 font-semibold text-center text-black2 font-glacialBold'>{value.name}</p>
                                <p className='text-center font-glacialBold -mt-4 pb-2 text-blac text-md md:text-sm'>{value.add}</p>
                            </div>
                        </div>

                    })}
                </div>
            </div>}

            {residential == false && <div className='flex flex-row justify-center'>

                <div className='flex flex-row justify-center flex-wrap gap-4 mb-20'>
                    {data2.map((value, index) => {
                        return <div key={index} className='flex flex-col rounded-xl border border-solid border-gray-300 shadow-xl my-4 w-5/12 md:w-3/12'>
                            <img src={value.img} className='w-full h-60 rounded-xl'></img>

                            <div>
                                <p className='text-xl text-black2 mt-2 mb-4 font-semibold text-center font-glacialBold'>{value.name}</p>
                                <p className='text-center meri -mt-4 pb-2 text-blac text-md md:text-sm'>{value.add}</p>
                            </div>
                        </div>

                    })}
                </div>
            </div>}

        </div>
    )
}

export default Gallery