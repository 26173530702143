import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import dtc1 from './assets/DTC.jpg';
import './Hero.css'
import dtc2 from './assets/Downtowncenter.JPG'
//import modi3 from './assets/7 Modi Baug Presentation_3.jpg'
import { Button } from "react-scroll";

import tick from './assets/checklist.png'


import LandSlide from './LandSlide';

const Hero = () => {
    const [count, setCount] = useState(1)

    const clkHnd = (num) => {
        setCount(num)
    }

    return (
        <div className=' mt-32 md:my-12'>

            <div className='flex flex-col justify-between gap-4 ml-12 mr-12 md:flex-row'>
                <div className='w-full order-2 md:w-[45vw] md:order-1'>
                    <div className='modiHero'>
                        {count == 1 && <img src={dtc1} className='w-full h-[50vh] md:w-[50vw] md:h-[80vh]'></img>}
                        {count == 2 && <img src={dtc2} className='w-full h-[50vh] md:w-[50vw] md:h-[80vh]'></img>}
                        {count == 3 && <img src='' className='w-[50vw] h-[80vh]'></img>}
                    </div>
                    <div className='flex flex-row justify-center gap-8 mt-4'>
                        <button onClick={() => { clkHnd(1) }} className='w-20 h-20'><img src={dtc1} className='w-full h-full'></img></button>
                        <button onClick={() => { clkHnd(2) }} className='w-20 h-20'><img src={dtc2} className='w-full h-full'></img></button>


                    </div>
                </div>

                <div className='w-full md:w-[45vw]'>
                    <p className='text-5xl mt-2 mb-1 font-semibold font-glacialBold text-black2 text-center'>DOWN TOWN CENTER</p>
                    <p className='text-center mb-4  font-glacialBold text-blac'>Erandwane, Pune</p>
                    <hr className='mb-4'></hr>

                    {/* <p className='text-center meri mb-4'>status : <b>completed</b></p> */}
                    <p className='text-center  font-glacialBold text-blac text-xl font-semibold'>20,000 sq.ft. onwards. Ready for fit-out.</p>
                    <p className=' font-glacialBold text-blac mt-4 text-lg text-justify mx-12'>DTC offers ready-to-use office premises ranging from 20,000 sq. ft. to 80,000 sq. ft. The building has 5 levels of office space and 5 levels of retail space. The property is built to suit, incorporating the special requirements of IT, ITeS companies, Banks, Insurance companies, and other Corporates.</p>
                    <Link to='/contact'><button className='bg-navy  font-glacialBold text-white mx-auto block mt-12 w-4/5 h-12 rounded'>Contact Us</button></Link>
                </div>

            </div>





            <p className='text-3xl mb-4 mt-12 ml-8 font-semibold text-black2 font-glacialBold'>Key Project Highlights</p>
            <div className=' w-8/12 ml-8'>
                <section className=' mt-8'>

                    <ul className='  pl-5 text-blac  font-glacialBold text-xl md:text-lg'>
                        <li className='flex flex-row gap-4 font-glacialBold'> <img src={tick} className='w-5 h-5'></img>Multi-level car park are attached to the office floor level </li>
                        <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>4 Passenger Lifts + 1 service lift </li>
                        <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Efficiency of the office area: 73%</li>

                    </ul>

                    <ul className='list-disc font-glacialBold text-blac  pl-5 meri text-xl md:text-lg'>
                        <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Part of a mixed-use development in Erandwane </li>
                        <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Located on a 100’ wide arterial road with high visibility, landmarks, and easy connectivity </li>
                        <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Total Leasable area (Phase 2) : ~1,20,000 sq.ft</li>
                        <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Office premises: ~ 14,5000 sq.ft to ~40,000 sq.ft </li>
                        <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Structure :Structure: 2 Basements + 12 Floors</li>
                        <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Usage: Office IT/ITeS /Commercial</li>
                        <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Ready office premises. of appx 14000 sqft and 200 seats appx</li>

                    </ul>
                </section>


            </div>

            <div className='flex flex-row justify-center mx-20 gap-12'>
                {/* left */}
                <div className=' flex-1'>
                    {/* <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold mb-3'>Construction</p>
                        <ul className='list-disc  pl-5 meri'>
                            <li>Earth quake resistant RCC framed structure</li>
                            <li>6" thick brick wall for outer and internal walls Stainless Steel & Glass ralling for Sit out</li>
                        </ul>
                    </section> */}

                    {/* <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold mb-3'>ELECTRICAL</p>
                        <ul className='list-disc  pl-5 meri'>
                            <li>100% generator back up for the common areas</li>
                            <li>Concealed copper wiring in the entire flat with ELCB Premium range Legrand modular switches & sockets.</li>
                        </ul>
                    </section> */}



                    {/* <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold mb-3'>FIRE FIGHTING</p>
                        <ul className='list-disc  pl-5 meri'>
                            <li>Modern fire fighting system</li>
                        </ul>
                    </section> */}

                    {/* <section className=' mt-8 mb-12'>
                        <ul className='list-disc  pl-5 meri'>
                            <p className='text-2xl font-semibold font-glacialBold mb-3'>PLUMBING, BATHROOM AND TOILETS</p>
                            <li>Concealed plumbing</li>
                            <li>Grohe/Toto (or similar) make CP fittings & Sanitary ware Wall hung WC units with concealed flush tank</li>
                            <li>Glass shower partition</li>
                            <li>Parallel kitchen platform with granite top Stainless Steel Sink with tap Designer Tile dado</li>
                            <li>Provision for Water Purifier & Dishwasher</li>

                        </ul>
                    </section> */}
                </div>

                {/* right */}
                {/* <div className=' flex-1'>
                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold mb-3'>FLOORING</p>
                        <ul className='list-disc  pl-5 meri'>
                            <li>Marble Flooring in Living. Dining area</li>
                            <li>Parquet flooring in Master Bedroom.</li>
                            <li>Vitrified Flooring in other Bedrooms</li>
                            <li>Granite / Marble / Designer combination files for flooring and Dado in toilets.</li>
                            <li>Antiskid Vitrified Tile for Sit outs</li>
                        </ul>
                    </section>



                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold mb-3'>EXTERNAL</p>
                        <ul className='list-disc  pl-5 meri'>
                            <li>External sand faced double coat plaster Weather proof Acrylic paint for external walls</li>
                        </ul>
                    </section>

                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold mb-3'>SECURITY</p>
                        <ul className='list-disc  pl-5 meri'>
                            <li>Keyless entry lock with card reader</li>
                            <li>Intercom facility</li>
                        </ul>
                    </section>

                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold mb-3'>WINDOW FRAMES & WINDOWS</p>
                        <ul className='list-disc  pl-5 meri'>
                            <li>Fenesta make windows frames & windows.</li>
                            <li>PLASTER & FINISHES INTERNAL</li>
                            <li>Gypsum finish for all internal walls Luster Paint</li>
                        </ul>
                    </section>
                </div> */}
            </div>


















            {/* <div>
                <LandSlide></LandSlide>
            </div> */}

        </div>
    )
}

export default Hero;