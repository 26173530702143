import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Hero from './Hero';
// import Gallery from './Gallery';
import Map from './Map';
import tick from './assets/checklist.png'

const Dtc = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>

            <Hero></Hero>
            {/* <Gallery></Gallery> */}

            <div>
                <p className='ml-8 meri text-3xl font-glacialBold text-black2 font-semibold'>Location Advantage</p>
                <ul className=' font-glacialBold text-blac  pl-5 ml-8 mt-4 mb-12 text-xl md:text-lg'>
                    <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>At the City Center</li>
                    <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Easy connectivity to all parts of the city</li>
                    <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>A Dense residential catchment</li>
                    <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Excellent F&B, shopping and retails options</li>

                    <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Shivaji Nagar Railway Station (4.9 Kms)</li>
                    <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Pune Junction (6 Kms)</li>
                    <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Karve Road (1.2Kms)</li>
                    <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>International Airport (14.1 Kms)</li>


                </ul>
            </div>

            <Link to='/residentianComp'><button className='bg-blue-900 font-semibold hover:bg-blue-800 text-white ml-12 px-3 py-2 rounded-md'>Back</button></Link>

            <div>
                <Map></Map>
            </div>
        </div>
    )
}

export default Dtc;