import React from "react";
import Slider from "react-slick";
import './AboutSlider.css'



function AboutSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "linear",
        arrows: false

    };
    return (
        <div className="slideCont w-full ">
            <Slider {...settings} className="w-full ">

                <div className="abtSlider4 ">

                </div>
                <div className="abtSlider11">

                </div>
                <div className="abtSlider3">

                </div>

                <div className="abtSlider5">

                </div>

                <div className="abtSlider6">

                </div>

            </Slider>
        </div>
    );
}

export default AboutSlider;
