import React, { useState } from 'react';
import './New.css'; // Assuming you have a CSS file for styling
import { useNavigate } from 'react-router-dom';
import img1 from './t1.png';
import img2 from './assets/t2.jpeg'; // Import other images

import westend from './assets/WestEndCrown1.webp'
import westMall from './assets/westendMall1.webp'
import westCenter from './assets/DTC.webp'
import Sankul from './assets/Sankul2.webp'
import Himali3 from './assets/Himali2.webp'


const New = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const navigate = useNavigate()

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const clkHnd = (index) => {
        console.log(index)
        if (index == 0) {
            navigate('/residentianComp')
        }
        if (index == 1) {
            navigate('/westend')
        }
        if (index == 2) {
            navigate('/DTC')
        }
        if (index == 3) {
            navigate('/gallery')
        }
        if (index == 4) {
            navigate('/gallery')
        }
    }

    const images = [westend, westMall, westCenter, Himali3, Sankul]; // Array of images
    const names = ['Westend Icon', 'Westend Mall', 'DTC', 'Himali', 'Sankul']; // Array of project names

    return (
        <div className='mt-20 -mb-80 lg:mt-20 md:mb-0'>
            <p className='text-black2 text-center mb-4  text-4xl font-bold font-glacialBold md:-mb-12'>Our Innovative Projects</p>
            {/* <p className='text-center mt-16 text-xl px-40 meri'>Groundbreaking Projects for a New Era of Living</p> */}
            <div className='w-full  flex justify-center items-center overflow-hidden mb-80 mt-0 md:mt-0 md:h-screen md:mb-0'>
                <div className='flex justify-center pb-12 align-center gap-4 w-full md:pb-0'>
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className={`h-96 rounded-xl transition-all duration-1000 justify-end flex-end relative bottom-0 bg-cover bg-center ${hoveredIndex === index ? 'w-6/12' : 'w-2/12'}`}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => { clkHnd(index) }}
                            style={{
                                backgroundImage: `url(${image})`,
                            }}
                        >
                            <p className='absolute rounded-2xl bottom-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center w-full text-center text-xl text-white bg-navy  font-glacialBold pb-1 pt-1'>
                                {names[index]}
                            </p>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    );
};

export default New;
