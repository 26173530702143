import { Outlet } from "react-router-dom";
import Nav from "../components/nav/Nav";
import Footer from "../components/footer/Footer";
import Footer2 from "../components/footer/Footer2";



const Root = () => {
    return <div>

        <Nav></Nav>
        <Outlet></Outlet>
        {/* <Footer2></Footer2> */}
        <Footer></Footer>

    </div>
};

export default Root;