import React, { useEffect } from "react";

function PrivecyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="container mx-auto py-5">
      <section className=" rounded p-4">
        <h1 className="mb-4 text-black2 text-center text-2xl font-glacialBold font-bold">Privacy Policy</h1>
        <div className="bg-white p-4 rounded">
          <div className="mb-4">
            <h5 className="text-2xl text-black2  font-glacialBold">Personal Information</h5>
            <p className="text-lg  font-glacialBold text-blac">
              Some personal information including addresses, phone numbers, e-mail addresses, and other demographic information is collected through inquiry forms. Suma Shilp Limited takes precautions to protect your individual/personal information from unauthorized use and makes internal use of your contact information only to inform you about projects and services that may interest you.
            </p>
          </div>

          <div className="text-xl  font-glacialBold text-blac">
            <h5 className="text-2xl  font-glacialBold text-black2">Electronic Mail</h5>
            <p className="text-lg text-justify  font-glacialBold text-blac">
              When you voluntarily send us electronic mail, we will keep a record of this information so that we can respond to you promptly. However, we do not disclose your information to other public bodies or individuals except as authorized by law.
            </p>
          </div>

          <div className="text-xl  font-glacialBold text-blac">
            <h5 className="text-2xl mt-3  font-glacialBold text-black2">Server Logs</h5>
            <p className="text-lg text-justify font-glacialBold">
              As you travel through the Suma Shilp Limited website, our servers log information about your session. Information logged includes items such as your IP address, what browser you are using, the time and date you visited, how long your session lasted, and what pages you visited. We use this information from our server logs primarily to learn about our visitors as a group, to track visitors and readership on our website.
            </p>
          </div>

          <div className=" text-blac  mt-4 rounded">
            <p className="text-lg text-justify  font-glacialBold text-blac">
              Suma Shilp Limited reserves the right to change this policy in any manner at any time without prior notice. If we make material changes to our privacy policy, the website will update the same.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrivecyPolicy;
