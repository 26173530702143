import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import "./Burger.css";
import { Link } from "react-router-dom";

function Burger() {
  const [isOpen, setIsOpen] = useState(false);
  const [showProjects, setShowProjects] = useState(false);

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const toggleProjects = () => {
    setShowProjects(!showProjects);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="content">
      <Menu
        right
        isOpen={isOpen}
        onStateChange={handleStateChange}
        customBurgerIcon={<div className="custom-burger-icon"><span></span><span></span><span></span></div>}
      >
        {/* <div className="menu-header">Menu</div> */}
        <Link to='/' className="menu-item meri" onClick={closeMenu}>
          Home
        </Link>

        <Link to='/westend' className="menu-item meri" onClick={closeMenu}>
          Westend
        </Link>

        <div className="menu-item project-dropdown meri">
          <div onClick={toggleProjects}>
            Projects
            <span className="dropdown-icon">{showProjects ? "−" : "+"}</span>
          </div>
          <div className={`dropdown ${showProjects ? 'show' : ''}`}>
            <Link to='/residentianComp' className="menu-item sub-item meri" onClick={closeMenu}>
              Ongoing
            </Link>
            <Link to='/completed' className="menu-item sub-item meri" onClick={closeMenu}>
              Completed
            </Link>
          </div>
        </div>

        <Link to='/gallery' className="menu-item meri" onClick={closeMenu}>
          Gallery
        </Link>
        <Link to='/media' className="menu-item meri" onClick={closeMenu}>
          Media
        </Link>
        <Link to='/opportunities' className="menu-item meri" onClick={closeMenu}>
          Opportunities
        </Link>
        <Link to='/career' className="menu-item meri" onClick={closeMenu}>
          Careers
        </Link>
        <Link to='/contact' className="menu-item meri" onClick={closeMenu}>
          Contact
        </Link>
      </Menu>
    </div>
  );
}

export default Burger;