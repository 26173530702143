import React from "react";
import Slider from "react-slick";
import './WestSlider.css'

function WestSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        cssEase: "linear"
    };
    return (
        <div className="overflow-hidden">
            <div className="slider-container">
                <Slider {...settings}>
                    <div className="westSlider1">
                        <h3>1</h3>
                    </div>
                    <div className="westSlider2">
                        <h3>2</h3>
                    </div>
                    <div className="westSlider3">
                        <h3>3</h3>
                    </div>
                    <div className="westSlider4">
                        <h3>4</h3>
                    </div>
                    <div className="westSlider5">
                        <h3>5</h3>
                    </div>

                </Slider>
            </div>
        </div>
    );
}

export default WestSlider;
