import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Padmavilas from "../Project/asset/Padmavilas+Enclave.webp";
import Sankul from "../Project/asset/Sankul.webp";
import Himali from "../Project/asset/Himali.webp";
import WestendMall from "../Project/asset/Westend.webp";
import WestendCentre1 from "../Project/asset/WestendCenter1.webp";
import WestendCentre2 from "../Project/asset/WestendCentre2.webp";
import WestendCentre3 from "../Project/asset/WestendCentre3.webp";

import tick from '../assets/checklist.png'

function Tabs() {
  const [activeTab, setActiveTab] = useState("residential");

  return (
    <div className="p-8 bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen">
      <div className="max-w-6xl mx-auto bg-white rounded-xl shadow-2xl overflow-hidden">
        {/* Tab Buttons */}
        <div className="flex p-2 bg-gray-100">
          <TabButton
            active={activeTab === "residential"}
            onClick={() => setActiveTab("residential")}
          >
            Residential
          </TabButton>
          <TabButton
            active={activeTab === "commercial"}
            onClick={() => setActiveTab("commercial")}
          >
            Commercial
          </TabButton>
        </div>

        {/* Tab Content */}
        <div className="p-6">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {activeTab === "residential" ? (
              <ResidentialContent />
            ) : (
              <CommercialContent />
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

function TabButton({ children, active, onClick }) {
  return (
    <button
      className={`flex-1 py-3 px-5 text-lg font-semibold rounded-lg transition-all duration-300 ${active
        ? "bg-white text-blac "
        : "text-blac hover:bg-gray-200"
        }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function ResidentialContent() {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-6 text-black2 font-glacialBold">
        Residential Projects
      </h2>
      <ProjectCard
        image={Padmavilas}
        title="Padma Vilas Enclave"
        description="Padma Vilas Enclave is a premium gated housing community project spread over 12 acres in the precincts of the Padma Vilas Palace in the Pune Cantonment area. The project was targeted mainly at Non-Resident Indians having a combination of duplexes to exclusive bungalows. Top-class internal and external amenities have been provided for this project."
        imageLeft={true}
      />
      <ProjectCard
        image={Himali}
        title="Himali"
        description="Himali is located in the Erandawane, almost a mile away from the main Deccan Gymkhana. A self-contained premium gated housing community, this project is developed over 10 acres. Designed by Padmashree Balkrishna V. Doshi, the project comprises over 200 units and caters to a wide range of clientele. The units range from one-bedroom terraced units to four-bedroom semi-detached bungalows."
        imageLeft={false}
      />
      <ProjectCard
        image={Sankul}
        title="Sankul"
        description="Sankul is a premium gated housing community project with 360,000 sq. ft. mixed residential development in Erandawane next to Deenanath Mangeshkar Hospital, Pune. The units range from 1, 2, 3 BHK and Row houses."
        imageLeft={true}
      />
      <OtherResidentialProjects />
    </div>
  );
}

function CommercialContent() {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-6 text-black2 font-glacialBold">
        Commercial Projects
      </h2>
      <ProjectCard
        image={WestendMall}
        title="Westend Mall"
        description="The Westend Mall is an integral component of a sprawling 2 million sq.ft. mixed-use development encompassing commercial, high street, and retail spaces, strategically located at Parihar Chowk, Aundh. The Nexus Westend Complex, covering 0.4 million square feet of leasable area, boasts an impressive 99% occupancy rate and houses over 145 stores."
        imageLeft={true}
      />
      <ProjectCard
        image={WestendCentre1}
        title="Westend Centre I"
        description="It offers bespoke built-to-suit facilities with ample parking, ranging from 25,000 to 382,000 sq.ft., boasting Grade A specifications and meticulous planning of essential services. Featuring a shopping complex and offices of major IT companies, it employs advanced technology including wide-span column grids and robust power backup for a seamless work environment."
        imageLeft={false}
      />
      <ProjectCard
        image={WestendCentre2}
        title="Westend Centre II"
        description="It spans 100,000 sq.ft. with floor plates of 26,000 sq.ft., presenting Grade A amenities and extensive parking options. Available for lease with plug-and-play or built-to-suit configurations, it caters to diverse business needs."
        imageLeft={true}
      />
      <ProjectCard
        image={WestendCentre3}
        title="Westend Centre III"
        description="It is a prestigious commercial space offering 150,000 sq.ft. of leasable area, with floor plates starting from 25,000 sq.ft. Equipped with ample parking and modern amenities, it's designed to meet the needs of growing businesses."
        imageLeft={false}
      />

      <div className="flex justify-center mt-8">
        <Link to='/gallery'><button className="bg-blac text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-blac transition duration-300 ease-in-out">
          View Gallery
        </button></Link>
      </div>
    </div>
  );
}

function ProjectCard({ image, title, description, imageLeft }) {
  return (
    <div
      className={`flex flex-col ${imageLeft ? "md:flex-row" : "md:flex-row-reverse"
        } items-center bg-white   overflow-hidden mb-8 transition-all duration-300 `}
    >
      <div className="md:w-2/5">
        <img src={image} alt={title} className="w-full h-64 object-cover" />
      </div>
      <div className="md:w-3/5 p-6">
        <h3 className="text-2xl font-semibold mb-3 text-black2 font-glacialBold">{title}</h3>
        <p className="text-blac shadow-xl px-2 text-justify leading-relaxed font-glacialBold text-xl md:text-lg">{description}</p>
      </div>
    </div>
  );
}

function OtherResidentialProjects() {
  return (
    <div className="bg-white  rounded-xl p-6 mt-8">
      <h3 className="text-2xl font-semibold mb-4 text-black2 font-glacialBold">
        Other Residential Projects in Prime Areas of Pune
      </h3>
      <ul className=" pl-5 text-blac text-lg space-y-2 font-glacialBold">
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Bhandarkar Road - Vanali, Vrundali</li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>BMCC Road - Ratnali</li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>
          Prabhat Road - Juili, Mrugali, Manali, Chaitrali, Geetali, Krupali

        </li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>
          Prabhat Road -
          Shamali, Aboli, Shwetali, Palawi, Vrukshali, Bhupali, Sonali
        </li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Sakal Nagar - Priyali</li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Kanchan Galli - Swapnali</li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>
          Law College road - Gandhali, Hansali, Meghali, Swarali, Lenyadri
          Vanali
        </li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Erandawane - Chinali</li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Bhosale Nagar - Shefali</li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Shivaji Nagar - Rupali, Pushpali</li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Gulmohar Path - Sumali</li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Damle Path - Mitali, Pranali</li>
        <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Kothrud - Rangali</li>
      </ul>
      <div className="flex justify-center mt-8">
        <Link to='/gallery'><button className="bg-blac text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ease-in-out">
          View Gallery
        </button></Link>
      </div>
    </div>
  );
}

export default Tabs;
