import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Hero from './Hero';
import Gallery from './Gallery';
import Map from './Map';

const Westend = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <Hero></Hero>
            {/* <Gallery></Gallery> */}
            <Link to='/residentianComp'><button className='bg-blue-900 font-semibold hover:bg-blue-800 text-white ml-12 px-3 py-2 rounded-md'>Back</button></Link>
            <div className='mx-4 mb-8'>
                <Map></Map>
            </div>
        </div>
    )
}

export default Westend;