import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import modibaug1 from './assets/Modibaug.jpg';
import './Hero.css'
import modi2 from './assets/7 Modi Baug Presentation_2.jpg'
import modi3 from './assets/7 Modi Baug Presentation_3.jpg'
import { Button } from "react-scroll";


import LandSlide from './LandSlide';

const Hero = () => {
    const [count, setCount] = useState(1)

    const clkHnd = (num) => {
        setCount(num)
    }

    return (
        <div className='my-12'>

            <div className='flex flex-row justify-between gap-4 ml-12 mr-12'>
                <div className='w-[45vw]'>
                    <div className='modiHero'>
                        {count == 1 && <img src={modibaug1} className='w-[50vw] h-[80vh]'></img>}
                        {count == 2 && <img src={modi2} className='w-[50vw] h-[80vh]'></img>}
                        {count == 3 && <img src={modi3} className='w-[50vw] h-[80vh]'></img>}
                    </div>
                    <div className='flex flex-row justify-center gap-8'>
                        <button onClick={() => { clkHnd(1) }} className='w-20 h-20'><img src={modibaug1}></img></button>
                        <button onClick={() => { clkHnd(2) }} className='w-20 h-20'><img src={modi2}></img></button>
                        {/* <button onClick={() => { clkHnd(3) }} className='w-20 h-20'><img src={modi3}></img></button> */}

                    </div>
                </div>

                <div className='w-[45vw]'>
                    <p className='text-5xl mt-2 mb-1 font-semibold font-glacialBold text-blac text-center'>7 Modibaug</p>
                    <p className='text-center mb-4 font-glacialBold text-blac'>Near Agriculture College, Off GaneshKhind Road, Pune</p>
                    <hr className='mb-4'></hr>

                    {/* <p className='text-center meri mb-4'>status : <b>Under Construction</b></p> */}
                    <p className='text-center text-blac text-xl font-glacialBold'>Luxurious 4 Bedroom Apartments & Duplexes</p>
                    <p className='text-center text-blac text-xl font-glacialBold'>3700 sq.ft. onwards. Ready for fit-out.</p>
                    <Link to='/contact'><button className='bg-navy font-glacialBold text-blac text-white mx-auto block mt-12 w-4/5 h-12 rounded'>Contact Us</button></Link>
                </div>

            </div>





            <p className='text-3xl mb-4 mt-12 text-blac text-center font-semibold font-glacialBold'>Amentites</p>
            <div className=' w-8/12 mx-auto block'>
                <section className=' mt-8'>

                    <ul className='list-disc  pl-5 font-glacialBold text-xl text-blac'>
                        <li className=''>Aesthetically designed entrance lobby 2 Automatic Elevators of Mitsubishi make.</li>
                        <li className=''>Swimming Pool with toddler's pool</li>
                        <li>Yoga/Meditation Area</li>
                        <li>Pantry and common toilet.</li>
                    </ul>
                </section>


            </div>

            <div className='flex flex-row justify-center mx-20 gap-12'>
                {/* left */}
                <div className=' flex-1'>
                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold text-blac mb-3'>Construction</p>
                        <ul className='list-disc  pl-5 font-glacialBold text-blac text-xl'>
                            <li>Earth quake resistant RCC framed structure</li>
                            <li>6" thick brick wall for outer and internal walls Stainless Steel & Glass ralling for Sit out</li>
                        </ul>
                    </section>

                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold text-blac mb-3'>ELECTRICAL</p>
                        <ul className='list-disc  pl-5 font-glacialBold text-blac text-xl'>
                            <li>100% generator back up for the common areas</li>
                            <li>Concealed copper wiring in the entire flat with ELCB Premium range Legrand modular switches & sockets.</li>
                        </ul>
                    </section>



                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold text-blac mb-3'>FIRE FIGHTING</p>
                        <ul className='list-disc  pl-5 font-glacialBold text-blac text-xl'>
                            <li>Modern fire fighting system</li>
                        </ul>
                    </section>

                    <section className=' mt-8 mb-12'>
                        <ul className='list-disc  pl-5 font-glacialBold text-xl text-blac'>
                            <p className='text-2xl font-semibold font-glacialBold text-blac mb-3'>PLUMBING, BATHROOM AND TOILETS</p>
                            <li>Concealed plumbing</li>
                            <li>Grohe/Toto (or similar) make CP fittings & Sanitary ware Wall hung WC units with concealed flush tank</li>
                            <li>Glass shower partition</li>
                            <li>Parallel kitchen platform with granite top Stainless Steel Sink with tap Designer Tile dado</li>
                            <li>Provision for Water Purifier & Dishwasher</li>

                        </ul>
                    </section>
                </div>

                {/* right */}
                <div className=' flex-1'>
                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold text-blac mb-3'>FLOORING</p>
                        <ul className='list-disc  pl-5 font-glacialBold text-blac text-xl'>
                            <li>Marble Flooring in Living. Dining area</li>
                            <li>Parquet flooring in Master Bedroom.</li>
                            <li>Vitrified Flooring in other Bedrooms</li>
                            <li>Granite / Marble / Designer combination files for flooring and Dado in toilets.</li>
                            <li>Antiskid Vitrified Tile for Sit outs</li>
                        </ul>
                    </section>



                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold mb-3 text-blac'>EXTERNAL</p>
                        <ul className='list-disc  pl-5 font-glacialBold text-blac text-xl'>
                            <li>External sand faced double coat plaster Weather proof Acrylic paint for external walls</li>
                        </ul>
                    </section>

                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold mb-3 text-blac'>SECURITY</p>
                        <ul className='list-disc  pl-5 font-glacialBold text-xl text-blac'>
                            <li>Keyless entry lock with card reader</li>
                            <li>Intercom facility</li>
                        </ul>
                    </section>

                    <section className=' mt-8 mb-12'>
                        <p className='text-2xl font-semibold font-glacialBold text-blac mb-3'>WINDOW FRAMES & WINDOWS</p>
                        <ul className='list-disc  pl-5 font-glacialBold text-xl text-blac'>
                            <li>Fenesta make windows frames & windows.</li>
                            <li>PLASTER & FINISHES INTERNAL</li>
                            <li>Gypsum finish for all internal walls Luster Paint</li>
                        </ul>
                    </section>
                </div>
            </div>


















            <div>
                <LandSlide></LandSlide>
            </div>

        </div>
    )
}

export default Hero;