import React from 'react';
import Typewriter from 'typewriter-effect';

const Layers = () => {
    return (
        <div className='text-center mt-4 text-5xl text-black2 font-semibold'>
            <Typewriter
                options={{
                    strings: ['Coming Soon...'],
                    autoStart: true,
                    loop: true,
                }}
            />
        </div>
    );
};

export default Layers;
