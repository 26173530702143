import React, { useEffect } from 'react'
import blur from './assets/blur.png'

function Career() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className=" min-h-screen">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center text-black2 mb-4  font-glacialBold">Join Our Team at Suma Shilp Ltd</h1>
        <div className="flex items-center w-full">
          <hr className="flex-1 border-t border-black" />
          <img src={blur} alt="Description" className="w-8 h-auto flex-shrink-0 mx-4" />
          <hr className="flex-1 border-t border-black" />
        </div>

        <section className="bg-white rounded-lg p-8 mb-4">
          <p className=" text-blac text-xl leading-relaxed  font-glacialBold">
            At Suma Shilp Ltd., we're committed to cultivating a community of dedicated real estate professionals. we provide the resources, networking opportunities, and support you need to excel.
          </p>
        </section>

        <section className="bg-white rounded-lg p-8 mb-4">
          <h2 className="text-2xl font-semibold text-black2 mb-6  font-glacialBold">Current Openings</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

            {[{ name: "Senior Architect (office space)" }, { name: "" }, { name: "" }, { name: "" }].map((item, index) => {
              return <div key={index} className='flex flex-row gap-4 bg-blue-50 py-4 rounded-md px-2'>
                <p>{index + 1}.</p>
                <p className='text-xl font-glacialBold'>{item.name}</p>
              </div>
            })}



          </div>
        </section>

        {/* <section className="bg-white rounded-lg shadow-md p-8 mb-12">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-6">Why Join Suma Shilp?</h2>
          <ul className="space-y-4">
            {[
              'Be part of a mission to empower Indian artisans',
              'Work with a diverse range of traditional crafts',
              'Opportunity for growth and skill development',
              'Collaborative and creative work environment'
            ].map((reason, index) => (
              <li key={index} className="flex items-start">
                <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>{reason}</span>
              </li>
            ))}
          </ul>
        </section> */}

        {/* <section className="bg-white rounded-lg shadow-md p-8 mb-12">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-6">How to Apply</h2>
          <p className="text-gray-700 mb-4">
            If you're interested in joining our team, please send your resume and a cover letter to <a href="mailto:hr@sumashilp.com" className="text-indigo-600 hover:underline">careers@sumashilp.com</a>. Be sure to mention the position you're applying for in the subject line.
          </p>
        </section> */}

        <section className="bg-white rounded-lg p-8">
          <h2 className="text-2xl font-semibold text-black2 mb-6  font-glacialBold">Contact Us</h2>
          <p className="text-blac text-xl mb-2  font-glacialBold">For any queries regarding career opportunities, please contact us at:</p>
          <p className="text-blac text-xl  font-glacialBold">Email: <a href="mailto:careers@sumashilp.com" className="text-blac hover:underline">hr@sumashilp.com</a></p>

        </section>
      </div>
    </div>
  )
}

export default Career