import './Video.css';
import wedVideo from './assets/hand.mp4';
//
//<video autoPlay loop muted controls>
// <source src={wedVideo} type="video/mp4"></source>
//  </video>


const Video = () => {
    return (
        <div className='holdVideo'>
            <video src={wedVideo} autoPlay loop muted className='vid'></video>


        </div>
    )
}

export default Video;