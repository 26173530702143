import React from 'react'
import './Hero5.css'
import heroImg from './assets/WestEndCrown1.jpg'

import HeroCard from './HeroCard'
import clouds from './assets/clouds2.mp4'

const Hero5 = () => {
    return (
        <div className='w-full sm:w-full'>
            <div className='holdVideo '>
                <video preload='auto' src={clouds} autoPlay loop muted className='vid'></video>
            </div>
            <div>
                <HeroCard></HeroCard>
            </div>
        </div>
    )
}

export default Hero5