import React from 'react'
import classes from './Hero.module.css'
import './HeroCard.css'

import img1 from '../assets/quality.png'
import img2 from './1.png'
import img3 from './2.png'
import img4 from './3.png'
import img5 from './5.png'
import img6 from './6.png'

//#004274  #165280

const HeroCard = () => {
    return (
        <div className='pt-4 pb-20 -mb-4 heroCardBg'>
            <div className='text-white flex flex-row justify-center gap-8 mt-20 h-84'>
                <div className=' border border-solid border-white w-3/12 rounded-2xl shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl'>
                    <img src={img2} className='mx-auto block w-12 mt-8'></img>
                    <p className='text-center mt-6 rounded-md text-xl font-semibold  font-glacialBold'>DIVERSITY</p>
                    <p className='mt-4 text-center px-4 font-glacialBold pb-2 font-base text-lg md: text-md'>Suma Shilp Limited has been a trailblazer in property management in Pune, dedicated to delivering quality and value. The company’s core strength comes from its strong reputation, which has been cultivated over many years.</p>
                    {/* <p className='text-center mt-8 text-blue-300'>Learn more</p> */}
                </div>

                <div className='border border-solid border-white w-3/12 rounded-2xl shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl'>
                    <img src={img3} className='mx-auto block w-12 mt-8'></img>
                    <p className='text-center mt-6 rounded-md text-xl font-semibold  font-glacialBold'>FINANCIAL STABILITY</p>
                    <p className='mt-4 text-center px-4  font-glacialBold font-base pb-2 text-lg md: text-md'>Suma Shilp Limited upholds a robust financial standing, enabling it to undertake large-scale real estate projects.</p>
                    {/* <p className='text-center mt-8 text-blue-300'>Learn more</p> */}
                </div>

                <div className='border border-solid border-white w-3/12 rounded-2xl  shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl'>
                    <img src={img4} className='mx-auto block w-12 mt-8'></img>
                    <p className='text-center mt-6 rounded-md text-xl font-semibold   font-glacialBold'>INNOVATIVE DESIGN AND TECHNOLOGY</p>
                    <p className='mt-4 text-center px-4  font-glacialBold font-base pb-2 text-lg md: text-md'>Suma Shilp Limited employs innovative architectural concepts and cutting-edge design techniques to develop visionary and impactful real estate projects.</p>
                    {/* <p className='text-center mt-8 text-blue-300'>Learn more</p> */}
                </div>



            </div>

            <div className='text-white flex flex-row justify-center gap-8 mt-12 h-84'>
                <div className='border border-solid border-white w-3/12 rounded-2xl shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl'>
                    <img src={img1} className='mx-auto block w-12 mt-8'></img>
                    <p className='text-center mt-6 rounded-md text-xl font-semibold  font-glacialBold'>REGULATORY COMPLIANCE</p>
                    <p className='mt-4 text-center px-4 font-glacialBold font-base pb-2 text-lg md: text-md'>Suma Shilp Ltd has an in-depth knowledge of local, state, control rules and national building codes and regulations, ensuring full compliance with all legal requirements.</p>
                    {/* <p className='text-center mt-8 text-blue-300'>Learn more</p> */}
                </div>

                <div className='border border-solid border-white w-3/12 rounded-2xl shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl'>
                    <img src={img5} className='mx-auto block w-12 mt-8'></img>
                    <p className='text-center mt-6 rounded-md text-xl font-semibold  font-glacialBold'>SUSTAINABILITY AND GREEN BUILDING</p>
                    <p className='mt-4  text-center px-4  font-glacialBold font-base pb-2 text-lg md: text-md'>Suma Shilp Ltd has extensive experience in achieving LEED certification and other recognized green building standards, showcasing its commitment to high environmental performance.</p>
                    {/* <p className='text-center mt-8 text-blue-300'>Learn more</p> */}
                </div>

                <div className='border border-solid border-white w-3/12 rounded-2xl shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl'>
                    <img src={img6} className='mx-auto block w-12 mt-8'></img>
                    <p className='text-center mt-6 rounded-md text-xl font-semibold   font-glacialBold'>REPUTATION AND REFERENCES</p>
                    <p className='mt-4  text-center px-4  font-glacialBold font-base pb-2 text-lg md: text-md'>Suma Shilp Ltd is renowned for its positive reputation within the real estate sector, demonstrating a strong commitment to excellence and professionalism.</p>
                    {/* <p className='text-center mt-8 text-blue-300'>Learn more</p> */}
                </div>
            </div>
        </div>
    )
}

export default HeroCard;