import React from 'react';
import classes from './Three.module.css'
import ig1 from './asset/Aboli.JPG'
import ig2 from './asset/Banali.JPG'
import ig3 from './asset/Bhupali.JPG'
import ig4 from './asset/Chaitrali.JPG'
import ig5 from './asset/Gandhali.JPG'
import ig6 from './asset/Geetali.JPG'
import ig7 from './asset/Himali3.JPG'
import ig8 from './asset/Vanali.JPG'
import ig9 from './asset/Manali.JPG'
import ig10 from './asset/Meghali1.JPG'
import ig11 from './asset/Padmavilas.JPG'

import ig13 from './asset/Parnali.JPG'
import ig14 from './asset/Priyali.JPG'
import ig15 from './asset/Pushpali.JPG'



const Four = () => {


    return (
        <>

     <div className={classes.a}> 
     
     <div  className={classes.a1}>

        <div  className={classes.a11}> <p className='text-3xl text-navy2 mb-3 font-proximal'> Residential Projects </p>  </div>

        <div  className={classes.a12}> <p className='text-xl mb-3 text-navy2 font-proximal'> Suma Shilp Ltd: Where Innovation Meets Excellence<br></br></p>
        <br/>
            <p className='text-xl mb-3 font-proximal'>At Suma Shilp, we bring innovation to every residential project, proving that nothing is impossible. Our commitment to excellence and quality ensures that each home stands as a testament to our expertise and dedication. </p>  </div>

     </div>


     <div  className={classes.a2}>

       <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig1} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Aboli </p>
<p className='text-xl  mb-3 font-proximal'> Law College Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig2} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Banali </p>
<p className='text-xl  mb-3 font-proximal'> Karve Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig3} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Bhupali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

</div> 




    <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig4} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Chaitrali  </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig5} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Gandhali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig6} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Gitali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

</div> 










    <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig7} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Himali </p>
<p className='text-xl  mb-3 font-proximal'> Erandwane </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig8} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Vanali </p>
<p className='text-xl  mb-3 font-proximal'> Lenyadri </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig9} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'>Manali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

</div> 










    <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig10} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Meghali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig11} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Padmavilas </p>
<p className='text-xl  mb-3 font-proximal'> Wanawdi </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig4} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Palvi </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

</div> 














    <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig13} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Pranali </p>
<p className='text-xl  mb-3 font-proximal'> Bhanadarkar Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig14} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Priyali </p>
<p className='text-xl  mb-3 font-proximal'> Sakal Nagar </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig15} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Pushpali </p>
<p className='text-xl  mb-3 font-proximal'> Apte Road </p>
        </div>

</div>

</div> 


















{/* 
    <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig1} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Rangali </p>
<p className='text-xl  mb-3 font-proximal'> Karve Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig2} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'>Ratnali </p>
<p className='text-xl  mb-3 font-proximal'> BMCC Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig4} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Rupali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

</div> 




















    <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig1} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Sankul </p>
<p className='text-xl  mb-3 font-proximal'> Erandwane  </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig2} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'>Sayali </p>
<p className='text-xl  mb-3 font-proximal'> CS College </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig4} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Shamali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

</div> 

























    <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig1} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Shefali </p>
<p className='text-xl  mb-3 font-proximal'> CS College </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig2} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Shwetali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig4} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Sonali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

</div> 



  <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig1} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Sumali </p>
<p className='text-xl  mb-3 font-proximal'> Gulmohor Peth </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig2} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Swapnali </p>
<p className='text-xl  mb-3 font-proximal'> Law College Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig4} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> SWarali </p>
<p className='text-xl  mb-3 font-proximal'>Law collge Road </p>
        </div>

</div>

</div> 






  <div  className={classes.a2x}>

   <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig1} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Vanali </p>
<p className='text-xl  mb-3 font-proximal'> Bhanadarkar Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig2} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Vrushali </p>
<p className='text-xl  mb-3 font-proximal'> Prabhat Road </p>
        </div>

</div>

  <div className={classes.a21d}> 
        <div className={classes.a21}> 
        <img src={ig4} className={classes.a21i}/>
        </div>

        <div  className={classes.a22}> 
<p className='text-3xl  text-navy mb-3 font-proximal'> Vrudanli </p>
<p className='text-xl  mb-3 font-proximal'> Law College Road </p>
        </div>

</div>

</div>  */}


     </div>


     
     </div>






        </>
    )
}


export default Four