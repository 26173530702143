import React, { useEffect } from 'react'
// import Hero from '../components/home/Hero';
import Hero5 from '../components/home/Hero5';
import New from '../components/home/New';
// import Legacy from '../components/home/Legacy';
// import Partners from '../components/home/Partners';
// import Qualitiy from '../components/home/Qualitiy';

import Story from '../components/story/Story';
import Proud from '../components/home/proud/Proud';


const Homepage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='w-full'>

            <Hero5></Hero5>
            <Story></Story>
            <New></New>


            <Proud></Proud>


        </div>
    )
}

export default Homepage;