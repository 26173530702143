import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Nav from './components/nav/Nav';

import Homepage from './pages/Homepage';
import Aboutpage from './pages/Aboutpage';
import West from './pages/West';
import Black from './pages/Black';
import Root from './pages/Root';
import Project from './pages/Project';
import Contact from './components/Contact/Contact'
import Modibaug from './pages/modibaug/Modibaug'
import Dtc from './pages/DTC/DTC';
import Westend from './pages/westend/Westend';
import Gallery from './pages/Gallery';
import ResidentianComp from './components/property2/ResidentianComp';
import Tabs from './components/property2/Completed';
import Career from './pages/Career';
import PrivecyPolicy from './pages/PrivecyPolicy';
import Disclaimer from './pages/Disclaimer';
import Opportunity from './pages/Opportunity';


const router = createBrowserRouter([{
  path: '/',
  element: <Root></Root>,

  children: [
    { path: '/', element: <Homepage></Homepage> },
    { path: '/media', element: <Aboutpage></Aboutpage> },
    { path: '/westend', element: <West></West> },
    { path: '/blackstone', element: <Black></Black> },
    { path: '/projects', element: <Project></Project> },
    { path: '/contact', element: <Contact></Contact> },
    { path: '/modibaug', element: <Modibaug /> },
    { path: '/gallery', element: <Gallery /> },
    { path: '/residentianComp', element: <ResidentianComp /> },
    { path: '/completed', element: <Tabs /> },
    { path: '/career', element: <Career /> },
    { path: '/policy', element: <PrivecyPolicy /> },
    { path: '/disclaimer', element: <Disclaimer /> },
    { path: '/DTC', element: <Dtc /> },
    { path: '/westend2', element: <Westend /> },
    { path: '/opportunities', element: <Opportunity /> },



  ]
}]);



const App = () => {
  return <RouterProvider router={router}></RouterProvider>

};

export default App;