import React from 'react';
import logo from '../../assets/ss1.png'
import './Pop.css';

const Popup = ({ onClose, deleteId, txt1, txt2, txt3, AdminDel, dishDeleteHnd }) => {
    return (
        <div className="delpopup-container">
            <div className="delpopup md:w-full mx-4">
                <div className='flex flex-row justify-between mb-2 '>
                    <img src={logo} className='w-36  -mt-8'></img>
                    <button onClick={onClose} className='rounded border border-solid border-black w-8 h-8 px-2 hover:bg-blue-100'>X</button>
                </div>

                <p className='text-justify font-glacialBold -mt-4 text-md'>Established in 1969, Suma Shilp Ltd has been at the forefront of real estate development and construction. The company's inception traces back to a partnership firm named Kanetkar Associates, founded by Mr. Anand Naralkar and Mr. Narayanrao V. Kanetkar. Over more than 5 decades, the firm has executed some of Pune's most prestigious projects, many of which have become city landmarks and significantly impacted the lives of Puneites.
                    In November 1969, Mr. Naralkar also initiated a family-owned partnership firm, Vastushilp, which later evolved into Suma Engineering Private Limited (now Suma Shilp Limited).
                    Mr. Pramod Naralkar, a civil engineering graduate from IIT Mumbai and a postgraduate in Structural Engineering and Business Administration from Duke University, USA, joined forces with his father in 1983.
                    Under the leadership of Mr. Pramod Naralkar, Suma Shilp Limited has grown steadily and is now recognized as one of Pune's most reputable construction companies. Most of the business comes from client references, highlighting the company’s positive image and goodwill.
                </p>

                <p className=' font-glacialBold text-2xl font-semibold mt-4 text-black2'>Our Aspiration</p>
                <p className='text-md mt-4 text-justify font-glacialBold'>uma Shilp Ltd is dedicated to advancing growth within the realm of commercial and residential real estate. Our team of experts is committed to transforming the real estate landscape through exceptional service, innovative solutions, and a deep understanding of our clients' needs. Our mission is to build thriving communities and deliver superior value by combining industry expertise with a dedication to integrity and sustainability.</p>


            </div>
        </div>
    );
};

export default Popup;