import React from 'react'

import O from './../components/Project/One'
import T from './../components/Project/Two'
import Th from './../components/Project/Three'
import F from './../components/Project/Four'


const pro = () =>{

    return (
        <>
<O />

<T></T>

<Th></Th>

<F></F>
     </>   
    )
}

export default pro;