import React from "react";
import Slider from "react-slick";

import floor1 from './assets/floorPlan.jpg'
import floor2 from './assets/floorPlan2.jpg'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import classes from './LandSlide.module.css'

function LandSlide() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className={classes.contain}>
            <p className="text-center mb-4 text-3xl meri font-semibold"> Floor Plans</p>
            <div className="slider-container">
                <Slider {...settings}>
                    <div>
                        <img src={floor1} className="w-3/5 mx-auto block"></img>
                    </div>
                    <div>
                        <img src={floor2} className="w-3/5 mx-auto block"></img>
                    </div>




                </Slider>
            </div>
        </div>
    );
}

export default LandSlide;
