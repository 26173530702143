import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import image1 from "../assets/1.jpeg";
import image2 from "../assets/2.jpeg";
import image3 from "../assets/3.jpeg";
import image4 from "../assets/4.jpeg";
import image5 from "../assets/5.jpeg";
import image6 from "../assets/6.jpeg";
import WestEndCrown1 from "../home/assets/WestEndCrown1.jpg";
import WestEndCrown2 from "../home/assets/WestEndCrown2.jpg";
//import Modibaug from "../home/assets/Modibaug.jpg"
import Modibaug from '../assets/MODIBAUG.webp'
import Modibaug2 from "../assets/modibaug2.jpg"

function ResidentianComp() {
  const cardDetails = [
    {
      title: "Downtwon The City Center(DTC)",
      location: "Erandwane, Pune",
      images: [image5, image6, image2],
      highlightedAmenities: [
        "DTC is an extensive mixed-use development spanning 5,10,000q.ft. (47,380 sq.m.) in Erandwane, Pune's centrally located prime area. Thoughtfully designed with meticulous attention to the needs of diverse businesses, DTC offers ready-to-use office spaces ranging from 20,000 sq.ft. to 80,000 sq.ft. The development features  retail spaces, and twelve levels of office space, tailored to accommodate the specialized requirements of IT, ITeS companies, banks, insurance firms, and corporates.",
      ],
      fullAmenities: [
        "Aesthetically designed entrance lobby",
        "2 Automatic Elevators of Mitsubishi make",
      ],
      link: '/DTC'
    },
    {
      title: "Westend Icon",
      location: "Aundh, Pune",
      images: [WestEndCrown1, WestEndCrown2],
      highlightedAmenities: [
        'Westend Icon, a joint venture by Chitrali Properties and the Blackstone Group, is a part of sophisticated mixed-use development spanning 0.36 million sq.ft. Located at Parihar Chowk, Aundh, it caters to modern corporate requirements with customizable floors and world-class amenities. Positioned conveniently near IT hubs like Baner, Hinjewadi, Shivaji Nagar, and Pimple Saudagar, it offers direct access to Westend Mall and an open terrace garden, enhancing comfort for its workforce. Supported by exclusive amenities and diverse retail, F&B, and entertainment options nearby, Westend Gateway ensures well-equipped, productive workspaces.',
      ],
      link: '/westend2'
    },

  ];

  const cardDetails2 = [
    {
      title: "7 Modibaug",
      location: "Shivaji Nagar, Pune",
      images: [Modibaug, Modibaug2],
      highlightedAmenities: [
        "7 Modi Baug, nestled off the Ganeshkhind road in Shivaji Nagar, stands as a pinnacle of luxury in Pune's esteemed locales. Offering 4-bedroom simplex and duplex apartments with an area upwards of 3,700 sqft, along with an expansive terrace this residential project epitomizes blissful living. Each home is meticulously crafted to elevate your lifestyle, characterized by lavish interiors and meticulous planning overseen by Suma Shilp Ltd. Professionals have ensured every detail exudes opulence and expertise in the field, maximizing room space and natural light to rejuvenate both body and soul. If you seek a new home for your mid-size or large family, look no further than 7 Modi Baug."
      ],
      link: 'https://www.sumashilp.com/7modibaug'
    },
  ]

  const Card = ({ details }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const { images, highlightedAmenities = [] } = details;

    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    const navigate = useNavigate();

    const nextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleReadMore = (link) => {
      // navigate('/', { state: { details } });
    };

    const prevImage = () => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    };

    return (
      <div className="max-w-6xl mx-auto mb-8 bg-white rounded-lg  overflow-hidden border border-solid border-gray-300">
        <div className="flex flex-col md:flex-row p-4">
          {/* Left side with image slider */}
          <div className="md:w-1/2 relative w-full h-96 border border-solid rounded-lg">
            <img
              src={images[currentImageIndex]}
              alt="Property"
              className="w-full h-full object-cover rounded-xl"
            />
            <span className="absolute top-2 left-2 text-white px-2 py-1 text-xs font-semibold rounded">

            </span>
            <button
              onClick={prevImage}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow"
            >
              &#10094;
            </button>
            <button
              onClick={nextImage}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow"
            >
              &#10095;
            </button>
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4 text-white">
              <p className="text-lg font-semibold">{details.price}</p>
              <p className="text-xs">{details.bhkInfo}</p>
            </div>
          </div>

          {/* Center side with details */}
          <div className="md:w-1/2 p-4">
            <h2 className="text-2xl font-bold mb-2 font-glacialBold text-black2">{details.title}</h2>
            <p className="text-sm  mb-4 font-glacialBold text-blac">{details.location}</p>

            <h3 className="text-lg font-semibold mb-2 text-blac font-glacialBold">About:</h3>
            <ul className="list-disc list-inside mb-4">
              {highlightedAmenities.map((amenity, index) => (
                <p key={index} className=" shadow-lg text-justify font-glacialBold text-blac text-xl md:text-base">
                  {amenity}
                </p>
              ))}
            </ul>
            <Link to={details.link}><button
              onClick={handleReadMore}
              className="bg-blac text-white px-4 py-2 rounded  transition duration-300"
            >
              Read More
            </button></Link>
          </div>
        </div>
      </div>
    );
  };


  // 
  const Card2 = ({ details }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const { images, highlightedAmenities = [] } = details;

    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    const navigate = useNavigate();

    const nextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleReadMore = (link) => {
      // navigate('/', { state: { details } });
    };

    const prevImage = () => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    };

    return (
      <div className="max-w-6xl mx-auto mb-8 bg-white rounded-lg  overflow-hidden border border-solid border-gray-300">
        <div className="flex flex-col md:flex-row p-4">
          {/* Left side with image slider */}
          <div className="md:w-1/2 relative w-full h-96 border border-solid rounded-lg">
            <img
              src={images[currentImageIndex]}
              alt="Property"
              className="w-full h-full object-cover rounded-xl"
            />
            <span className="absolute top-2 left-2 text-white px-2 py-1 text-xs font-semibold rounded">

            </span>
            <button
              onClick={prevImage}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow"
            >
              &#10094;
            </button>
            <button
              onClick={nextImage}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow"
            >
              &#10095;
            </button>
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4 text-white">
              <p className="text-lg font-semibold">{details.price}</p>
              <p className="text-xs">{details.bhkInfo}</p>
            </div>
          </div>

          {/* Center side with details */}
          <div className="md:w-1/2 p-4">
            <h2 className="text-2xl font-bold mb-2 font-glacialBold text-black2">{details.title}</h2>
            <p className="text-sm  mb-4 font-glacialBold text-blac">{details.location}</p>

            <h3 className="text-lg font-semibold mb-2 text-blac font-glacialBold">About:</h3>
            <ul className="list-disc list-inside mb-4">
              {highlightedAmenities.map((amenity, index) => (
                <p key={index} className=" shadow-lg text-justify font-glacialBold text-blac text-xl md:text-base">
                  {amenity}
                </p>
              ))}
            </ul>
            <a target="_blank" href='https://www.sumashilp.com/7modibaug'><button

              className="bg-blac text-white px-4 py-2 rounded  transition duration-300"
            >
              Read More
            </button></a>
          </div>
        </div>
      </div>
    );
  };
  // 

  return (
    <div
      style={{
        backgroundColor: "#F7F7F7",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <div className="space-y-8">
        {cardDetails.map((details, index) => (
          <Card key={index} details={details} />
        ))}
      </div>

      <div className="space-y-8">
        {cardDetails2.map((details, index) => (
          <Card2 key={index} details={details} />
        ))}
      </div>
    </div>
  );
}

export default ResidentianComp;