import React from 'react';
import classes from './Three.module.css'
import ig1 from './asset/DTC.jpg'
import ig2 from './asset/SumaCentre.jpg'

import ig4 from './asset/Tacohouse.JPG'
import ig5 from './asset/WestendCentre3.JPG'
import ig6 from './asset/Westend.webp'

const Three = () => {


        return (
                <>

                        <div className={classes.a}>

                                <div className={classes.a1}>

                                        <div className={classes.a11}> <p className='text-3xl text-navy2 mb-3 font-proximal'> Commercial Projects </p>  </div>

                                        <div className={classes.a12}> <p className='text-xl mb-3 text-navy2 font-proximal'> Suma Shilp Ltd: Where Innovation Meets Excellence<br></br></p>
                                                <br />
                                                <p className='text-xl mb-3 font-proximal'>At Suma Shilp Ltd, we infuse innovation into every project, proving that nothing is impossible. Our commitment to excellence and quality ensures that each development stands as a testament to our expertise and dedication. </p>  </div>

                                </div>


                                <div className={classes.a2}>

                                        <div className={classes.a2x}>

                                                <div className={classes.a21d}>
                                                        <div className={classes.a21}>
                                                                <img src={ig1} className={classes.a21i} />
                                                        </div>

                                                        <div className={classes.a22}>
                                                                <p className='text-3xl text-navy mb-3 font-proximal'> DTC </p>
                                                                <p className='text-xl  mb-3 font-proximal'> Erandwane </p>
                                                        </div>

                                                </div>

                                                <div className={classes.a21d}>
                                                        <div className={classes.a21}>
                                                                <img src={ig2} className={classes.a21i} />
                                                        </div>

                                                        <div className={classes.a22}>
                                                                <p className='text-3xl text-navy mb-3 font-proximal'> Suma Center </p>
                                                                <p className='text-xl  mb-3 font-proximal'> Erandwane </p>
                                                        </div>

                                                </div>

                                                <div className={classes.a21d}>
                                                        <div className={classes.a21}>
                                                                <img src={ig4} className={classes.a21i} />
                                                        </div>

                                                        <div className={classes.a22}>
                                                                <p className='text-3xl text-navy mb-3 font-proximal'> Taco House </p>
                                                                <p className='text-xl  mb-3 font-proximal'> Law College Road </p>
                                                        </div>

                                                </div>

                                        </div>



                                        <div className={classes.a2x}>

                                                <div className={classes.a21d}>
                                                        <div className={classes.a21}>
                                                                <img src={ig5} className={classes.a21i} />
                                                        </div>

                                                        <div className={classes.a22}>
                                                                <p className='text-3xl text-navy mb-3 font-proximal'> Westend Center</p>
                                                                <p className='text-xl  mb-3 font-proximal'> Aundh </p>
                                                        </div>

                                                </div>

                                                <div className={classes.a21d}>
                                                        <div className={classes.a21}>
                                                                <img src={ig6} className={classes.a21i} />
                                                        </div>

                                                        <div className={classes.a22}>
                                                                <p className='text-3xl text-navy mb-3 font-proximal'> Westend Mall </p>
                                                                <p className='text-xl  mb-3 font-proximal'> Aundh </p>
                                                        </div>

                                                </div>



                                        </div>






                                </div>



                        </div>






                </>
        )
}


export default Three