import React from 'react';



const Partners = () => {

    const technology = [
        { id: 1, name: 'Pizza Hut Express' },
        { id: 2, name: 'Sugar Cosmetics' },
        { id: 3, name: 'UNI (Unicorn) by Apple' },
        { id: 4, name: 'Starbucks Coffee' },
        { id: 5, name: 'Mi Studio' },
        { id: 6, name: 'ibm' },
        { id: 7, name: 'Burger King' },
        { id: 8, name: 'KFC' },
        { id: 9, name: 'Nykaa Luxe' },
        { id: 10, name: 'Lenovo' },
        { id: 11, name: 'Adidas' },
        { id: 12, name: 'Baggit' },
        { id: 13, name: 'Being Human' },
        { id: 14, name: 'Jockey' },
        { id: 15, name: 'United Colors Of Benetton' },
    ];

    return (
        <div className='mb-20 mt-20'>
            <p className='text-center text-4xl text-black2 font-bold  font-glacialBold'> Key Tenants</p>

            <div className='mt-8'>

                <div className='flex flex-row justify-center flex-wrap gap-12 mx-8'>
                    {technology.map(org => (
                        <div key={org.id} className=''>
                            <p className='text-2xl text-justify shadow-lg px-4 text-blac font-semibold  font-glacialBold'>{org.name}</p>
                        </div>
                    ))}
                </div>
            </div>



        </div>
    );
};

export default Partners;
