import React, { useState } from "react";
import { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Nav.css";
//import logo from './logo.png';
import logo from "./../assets/ss1.png"
import Burger from "./Burger";


function Nav() {
    /*Navbar */
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);



    return (
        <div className="mb-20">
            <header>
                <nav className="navbar">
                    <div className="nav-container">
                        <NavLink exact to="/" className="nav-logo">
                            <img src={logo} alt="sumashilp" className='w-36' ></img>
                        </NavLink>



                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <Burger></Burger>
                            {/* <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    Home
                                </NavLink>
                            </li> */}

                            <li className="nav-item">
                                <div class="dropdown">

                                    {/* <li className="act2 mr-8" ><NavLink className="act2">Projects</NavLink></li> */}
                                    <div class="dropdown-content">
                                        {/* <NavLink to='/event' activeClassName="active" className="nav-links" onClick={handleClick}>Westend</NavLink>
                                        <NavLink to='/ourService' activeClassName="active" className="nav-links" onClick={handleClick}>Blackstone</NavLink> */}
                                    </div>
                                </div>
                            </li>



                            {/* <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/about"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    About Us
                                </NavLink>
                            </li> */}

                            {/* <li className="nav-item">
                                <div class="dropdown">

                                    <li className="act2" ><NavLink className="act2">Services</NavLink> <img src='' className='plus'></img></li>
                                    <div class="dropdown-content">
                                        <NavLink to='/event' activeClassName="active" className="nav-links" onClick={handleClick}>Events</NavLink>
                                        <NavLink to='/ourService' activeClassName="active" className="nav-links" onClick={handleClick}>Services</NavLink>
                                    </div>
                                </div>

                            </li> */}



                            {/* <li className="nav-item">
                                <div class="dropdown">

                                    <li className="act2" ><NavLink className="act2">Packages</NavLink> <img src='' className='plus'></img></li>
                                    <div class="dropdown-content">

                                        <NavLink to='/platinum1' activeClassName="active" className="nav-links" onClick={handleClick}>Platinum</NavLink>
                                        <NavLink to='/golden1' activeClassName="active" className="nav-links" onClick={handleClick}>Golden</NavLink>
                                        <NavLink to='/silver1' activeClassName="active" className="nav-links" onClick={handleClick}>Silver</NavLink>

                                    </div>
                                </div>

                            </li> */}






                            {/* <li className="nav-item"><NavLink to='/profile' onClick={handleClick} activeClassName="active" className="nav-links" span={true} smooth={true}>Gallery</NavLink></li> */}




                            {/* <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/contact"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    Contact Us
                                </NavLink>
                            </li> */}

                        </ul>
                        <div className="nav-icon" onClick={handleClick}>
                            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
                        </div>
                    </div>
                </nav>

            </header>

        </div>
    );
}

export default Nav;