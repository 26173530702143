import React, { useEffect } from 'react';
//#0085B1
import img1 from './assets/developer.png'
import img2 from './assets/land.png'
import blur from './assets/blur.png'
export default function Opportunity() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <section className="py-8">
            <div className="container mx-auto px-4">
                <h1 className="text-center text-black2 font-glacialBold mb-4 text-5xl font-bold">Opportunities</h1>

                <div className="flex items-center w-full">
                    <hr className="flex-1 border-t border-black" />
                    <img src={blur} alt="Description" className="w-8 h-auto flex-shrink-0 mx-4" />
                    <hr className="flex-1 border-t border-black" />
                </div>

                <div className="w-full  px-4 mb-8">
                    <div className="h-full  rounded-lg overflow-hidden  shadow-xl ">
                        <div className="p-6">
                            <h2 className="text-black2 mb-6  flex flex-row gap-4  text-2xl font-semibold">
                                <img src={img2} className='w-10 h-10'></img>Joint Venture & Outright Purchase
                            </h2>
                            <p className="mb-4 font-glacialBold text-blac  text-justify text-xl md:text-lg">
                                We strongly believe that 'if efforts are mutual, success is certain'. You can form a joint venture informally with just a handshake, but it's always best to have something in writing. We ensure and encourage complete transparency while forming a joint venture. We walk you through different legalities involved and assist you with the paperwork. Joint Ventures are of strategic importance for companies to develop residential, commercial, and corporate complexes. Strategic collaborations offer a plethora of opportunities and foster customer relationships that stay forever.
                            </p>
                            <p className='font-glacialBold text-blac text-xl text-justify md:text-lg'>
                                We are open to discussing your dream work, business goals, tax issues, and ownership agreements to gain clarity when striking the deal. At present, the group is seeking proposals for Joint Development of projects & outright purchase of land parcels from Landowners in Pune, Mumbai & Goa.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full  px-4 mb-8">
                    <div className="h-full  rounded-lg overflow-hidden  shadow-xl ">
                        <div className="p-6">
                            <h2 className="text-black2 mb-6  flex flex-row gap-4  text-2xl font-semibold">
                                <img src={img1} className='w-10 h-10'></img>Redevelopment
                            </h2>
                            <p className="mb-4 font-glacialBold text-blac text-xl text-justify md:text-lg">
                                Suma Shilp Ltd. is tailored to meet the growing needs of a diverse range of transformation happening in Pune city. This represents a strategic opportunity for Redevelopment which has gained more momentum as people are becoming increasingly aware of the need to upgrade their homes and lifestyles.
                            </p>
                            <p className='mb-2 font-glacialBold text-blac text-xl text-justify md:text-lg'>
                                Limited availability of open space and an ever-growing need for accommodation has put Pune in dire need of renovating its housing patterns.
                            </p>
                            <p className='font-glacialBold text-blac text-xl text-justify md:text-lg'>
                                We understand that a home is not a place; it's a feeling which should be cherished forever. And our redevelopment projects help you nurture your bond with the same locality and neighborhood with an improved lifestyle.
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="flex flex-wrap -mx-4">
                    <div className="w-full  px-4 mb-8">
                        <div className="h-full  rounded-lg overflow-hidden shadow-xl">
                            <div className="p-6">
                                <h2 className="text-black2 mb-6 flex flex-row gap-4  font-glacialBold   text-2xl font-semibold"><img src={img1} className='w-10 h-10'></img>Redevelopment</h2>
                                <p className="mb-2 font-glacialBold text-blac text-lg text-justify">Suma Shilp Ltd. is tailored to meet the growing needs of a diverse range of transformation happening in Pune city. This represents a strategic opportunity for Redevelopment which has gained more momentum as people are becoming increasingly aware of the need to upgrade their homes and lifestyles.</p>
                                <p className="mb-2 font-glacialBold text-blac text-lg text-justify ">Limited availability of open space and an ever-growing need for accommodation has put Pune in dire need of renovating its housing patterns.</p>
                                <p className=" font-glacialBold text-blac text-lg text-justify ">We understand that a home is not a place; it's a feeling which should be cherished forever. And our redevelopment projects help you nurture your bond with the same locality and neighborhood with an improved lifestyle.</p>
                            </div>
                        </div>
                    </div>

                   
                </div> */}
            </div>


        </section>
    );
}