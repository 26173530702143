import React from 'react';
import classes from './One.module.css'
import ig1 from './asset/WestendCentre3.JPG'
import ig2 from './asset/Sankul2.JPG'
import ig3 from './asset/SumaHouse.JPG'

const One = () => {


return(
    <>
    
    <div className={classes.a}>

<div className={classes.b}>

    <div className={classes.b1}>

        <p className='text-3xl text-navy2 mb-3 font-proximal'>Discovering Innovation : </p> <br></br> 
        <p className='text-3xl text-navy mb-3 font-proximal'>A Deep Dive into Luxurious Projects</p>

    </div>

    <div className={classes.b2}>

<img src={ig1} className={classes.ig1} />

    </div>
    
    <div className={classes.b2}>

<img src={ig2} className={classes.ig2} /> 
<div className={classes.ig3} /> 
        
    </div>

</div>

    </div>
    
    </>
)

}


export default One