import React, { useEffect } from 'react';
import './West.css';
import WestSlider from '../components/property/WestSlider';
import Amentities from '../components/property/Amentities';
import Property2 from '../components/property2/Property2';
import Partners from '../components/home/Partners';

//import hand from './assets/hand5.jpg'

import blur from './assets/blur.png'

import img1 from './westend/assets/WestEndCrown1.webp'
import westMall from '../components/home/assets/westendMall1.jpg'

import Video from './Video';


const West = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='overflow-hidden'>
            <Video></Video>


            {/* <div className='westBanner'></div> */}




            <div className='mt-20 z-20'>

            </div>

            {/* <Vertical></Vertical> */}

            {/* <div className='mx-20'>
                <p className='font-glacialBold text-xl mb-2 text-blac'>Chitrali Properties, a division of Suma Shilp Ltd., has partnered with Nexus Select Trust to spearhead the prestigious Westend project. Situated in the heart of Aundh, in western Pune, this development forms a pivotal component of a sprawling 1.6 million square feet mixed-use complex. </p>
                <p className='font-glacialBold text-xl mb-2 text-blac'>Nexus Select Trust India, a distinguished leader in the retail real estate sector and proudly part of the esteemed Blackstone Group, has revolutionized the shopping and entertainment landscape throughout India since its establishment in 2016. Nexus Select Trust oversees a diverse portfolio comprising 17 malls spanning 14 cities and with approximately 10 million square feet of premium Grade A retail space and their malls are meticulously crafted to offer unparalleled shopping, dining, and entertainment experiences.</p>

                <p className='font-glacialBold text-xl text-blac'>This collaboration signifies a significant synergy between Suma Shilp Ltd.'s extensive development expertise and Nexus Select Trust's established leadership in retail real estate, promising to redefine the commercial landscape of the region.</p>
            </div> */}

            <div className='mt-12 mb-8'>

                <p className='text-3xl font-semibold ml-20 font-glacialBold mb-4 text-black2'>Westend Nexus Mall</p>
                <div className='flex flex-col mt-12 mb-20 justify-center gap-16 md:flex-row'>
                    <div className='w-full md:w-5/12'>
                        <img src={westMall} className='w-full h-full'></img>
                    </div>
                    <div className='w-full shadow-xl px-8 flex flex-col justify-center h-full md:w-5/12'>

                        <p className='mb-4 text-justify font-glacialBold text-blac text-xl md:text-lg'>The Westend Nexus Mall is a premier shopping and entertainment destination, renowned for its expansive layout and diverse offerings. Situated at Parihar Chowk, Aundh, in a central commercial district, the mall serves as a pivotal hub for retail, dining, and leisure activities. Spanning approximately 0.4 million sq. ft, it houses 150 stores, including a mix of high-end international brands, popular national chains, and specialty shops. In addition to it’s extensive retail options, The Westend Nexus Mall features a variety of dining venues, from a large food court to fine dining restaurants and cafés. Visitors can enjoy modern entertainment amenities, such as a multiplex cinema and dedicated entertainment zones. With ample parking, concierge services, and free Wi-Fi, The Westend Nexus Mall is designed to provide convenience and comfort, solidifying its reputation as a dynamic and valuable destination for shopping and leisure.
                        </p>
                        <p className='mb-4 text-justify font-glacialBold text-blac text-xl md:text-lg'>In addition to its extensive retail options, Westend Nexus Mall features a variety of dining venues, from a large food court to fine dining restaurants and cafés. Visitors can enjoy modern entertainment amenities, such as a multiplex cinema and dedicated entertainment zones. With ample parking, concierge services, and free Wi-Fi, Westend Nexus Mall is designed to provide convenience and comfort, solidifying its reputation as a dynamic and valuable destination for shopping and leisure.</p>
                    </div>

                </div>

                <p className='text-3xl font-semibold ml-20 mb-12 font-glacialBold  text-black2'>Westend ICON</p>

                <div className='flex flex-col justify-center gap-16 md:flex-row'>

                    <div className='w-full shadow-xl px-8 h-full flex flex-col justify-center md:w-5/12'>

                        <p className='mb-2 text-justify text-blac font-glacialBold text-xl md:text-lg'>The Westend Icon project, located in the heart of Aundh, western Pune, represents a major development within a sprawling 1.6 million square feet mixed-use complex. This ambitious project features an extensive office space with a total leasable area of 360,000 square feet, providing substantial floor plates of 30,000 square feet each. The Westend Icon is designed to accommodate a variety of business needs, combining modern architectural elements with functional office spaces to enhance the commercial landscape of the region.</p>
                        <p className=' text-justify font-glacialBold text-blac text-xl md:text-lg'>By offering expansive office spaces and leveraging its prime location, the Westend Icon is set to become a key player in the area's business district, aiming to attract top-tier companies and redefine the commercial real estate market in Pune.</p>
                    </div>

                    <div className='w-full md:w-5/12'>
                        <img src={img1} className=' w-full h-4/5'></img>
                    </div>
                </div>


            </div>

            <div className="flex items-center w-full">
                <hr className="flex-1 border-t border-black" />
                <img src={blur} alt="Description" className="w-8 h-auto flex-shrink-0 mx-4" />
                <hr className="flex-1 border-t border-black" />
            </div>

            <Partners></Partners>
        </div>
    );
};

export default West;
