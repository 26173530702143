import classes from './Map.module.css';
import { useEffect } from 'react';

/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.2522787316925!2d73.85394187355254!3d18.51749836927134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c06f81756e0d%3A0x6a87c9da695b2350!2sSamarth%20Plaza%2C%20Tapkir%20Gali%2C%20Budhwar%20Peth%2C%20Pune%2C%20Maharashtra%20411002!5e0!3m2!1sen!2sin!4v1681811024675!5m2!1sen!2sin"
            className={classes.map}
            width='100%'
            height="400"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe> */

/*
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15129.162839581479!2d73.8069071!3d18.560928!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bff9ca29924d%3A0x64cb13e2f282726a!2sWestend%20Icon!5e0!3m2!1sen!2sin!4v1722947705297!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 */

const Map = () => {



    return <div className={classes.contain} >
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15129.162839581479!2d73.8069071!3d18.560928!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bff9ca29924d%3A0x64cb13e2f282726a!2sWestend%20Icon!5e0!3m2!1sen!2sin!4v1722947705297!5m2!1sen!2sin"
            className={classes.map}
            width="100%"
            height="400"
            style={{ border: "0" }}
            allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>



    </div>
};

export default Map;