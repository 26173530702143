import React from "react";
import './Proud.css'
import { FaTrophy, FaUsers, FaUserTie, FaClock, FaBuilding } from "react-icons/fa";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

import construction from './contruction.png'
import building from './building.png'

const AnimatedCountUp = ({ end, duration }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <span ref={ref}>
            {inView ? <CountUp end={end} duration={duration} /> : "0"}
        </span>
    );
};

const Proud = () => {
    const achievements = [
        {
            icon: building,
            number: 75,
            title: "Successful Projects",

        },
        {
            icon: building,
            number: 40,
            title: "Lakh Sq. ft Total development.",

        },
        {
            icon: building,
            number: 30,
            title: "Lakh sq. ft Commercial development",

        },
        {
            icon: building,
            number: 10,
            title: "Lakh Sq. ft Residential development",

        },
    ];

    return (
        <div className="background-container py-20 px-8 ">
            <div className="max-w-6xl mx-auto">
                <p className="proudHead text-5xl  md:text-5xl font-bold  text-center mb-12  font-glacialBold">
                    We Have a Lot to Be <span className="text-blue-300  font-glacialBold">Proud Of</span>
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 font-glacialBold">
                    {achievements.map((item, index) => (
                        <div
                            key={index}
                            className="bg-gradient-to-b from-white to-blue-100 border border-solid   rounded-lg p-6 shadow-lg transform hover:scale-105 transition duration-300"
                        >
                            <img src={item.icon} className="w-12 mx-auto block"></img>
                            <h3 className="text-3xl font-bold text-gray-800 mb-2   font-glacialBold md:text-2xl">
                                <AnimatedCountUp end={item.number} duration={2.5} />
                                {item.number > 1 ? "+" : ""}
                            </h3>
                            <h4 className="text-xl text-left  font-semibold text-gray-800 mb-2  font-glacialBold">
                                {item.title}
                            </h4>
                            <p className="text-blac font-glacialBold">{item.description}</p>
                        </div>
                    ))}
                </div>

                {/* <div className="text-center mt-12">
                    <button className="bg-white text-blac font-bold py-3 px-8 rounded-md hover:bg-gray-300 transition duration-300 text-lg  font-glacialBold">
                        With over 50+ years of experience For us, the customer is the soul
                        of the company. And it is in serving them that we find the greatest
                        satisfaction
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default Proud;



















// import React from 'react'

// const Proud = () => {
//     return (
//         <div className='flex bg-gray-700 py-16 gap-8 px-8 mt-12 mb-20'>
//             <div className='flex-1 text-white'>
//                 {/* <p className='text-md font-medium mb-8 meri'>ACCOMPLISHMENTS</p> */}
//                 <p className='text-3xl font-bold mb-3 font-proximal'> We have much to be proud of, with over<br></br> 50+ years of experience.</p>
//                 <p className='text-3xl font-bold mb-3 font-proximal'>For us, the customer is the soul of the company. And it is in serving them that we find the greatest satisfaction.</p>
//                 <p className='text-3xl font-bold mb-3 font-proximal'></p>


//                 <button className='bg-btn text-xl font-medium transition duration-700  mt-12 px-10 text-white hover:text-black hover:bg-white hover:border-2 hover:border-blue-600  rounded-l-full rounded-r-full py-4'>Read More</button>
//             </div>
//             <div className='flex-1 text-white'>
//                 <div>
//                     <p className='text-4xl font-bold font-proximal'>75+</p>
//                     <p className='text-2xl font-bold font-proximal'>Successful projects.</p>
//                     {/* <p className='mt-4 text-xl text-gray-400 font-medium'>Each of our projects is a reason for pride. All of them are bold, progressive and timeless.</p> */}
//                 </div>

//                 <div className='mt-32'>
//                     <p className='text-4xl font-bold font-proximal'>3+</p>
//                     <p className='text-2xl font-bold font-proximal'>Million Sq. ft Commercial development.</p>
//                     {/* <p className='mt-4 text-xl text-gray-400 font-medium'>It is important for us not to disappoint the people who have entrusted us with construction, from the smallest to the largest projects.</p> */}
//                 </div>
//             </div>

//             <div className='flex-1 text-white'>
//                 <div>
//                     <p className='text-4xl font-bold font-proximal'>4+</p>
//                     <p className='text-2xl font-bold font-proximal'>Million Sq. ft. Total development.</p>
//                     {/* <p className='mt-4 text-xl text-gray-400 font-medium'>Since our inception, we have successfully completed over 4 million square feet of  projects. </p> */}
//                 </div>

//                 <div className='mt-32'>
//                     <p className='text-4xl font-bold font-proximal'>1+</p>
//                     <p className='text-2xl font-bold font-proximal'>Million Sq. ft Residential development.</p>
//                     {/* <p className='mt-4 text-xl text-gray-400 font-medium'>We have implemented the most daring architectural solutions, built reliable buildings, and improved the appearance of streets since 1969.</p> */}
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Proud