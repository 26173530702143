import React from 'react'

import living from './assets/modibaug2.jpeg'
import kitchen from './assets/modibaug3.jpeg'
import swim from './assets/modibaug1.jpeg'
import bed from './assets/modibaug4.jpeg'

const Gallery = () => {
    return (
        <div className='mb-28 mx-2'>
            <p className="text-center mb-8 text-3xl font-glacialBold text-blac font-semibold">Gallery</p>

            <div className='flex flex-row justify-center gap-4'>
                <div className='flex-1'>
                    <img src={living} className=' w-full h-full'></img>
                    <p className='text-center font-glacialBold text-blac text-xl mt-2 '>Living Room</p>
                </div>

                <div className='flex-1'>
                    <img src={kitchen} className=' w-full h-full'></img>
                    <p className='text-center font-glacialBold text-blac text-xl mt-2 '>Kitchen</p>
                </div>

                <div className='flex-1'>
                    <img src={swim} className=' w-full h-full'></img>
                    <p className='text-center font-glacialBold text-blac text-xl mt-2 '>Swimming Pool</p>
                </div>

                <div className='flex-1'>
                    <img src={bed} className=' w-full h-full'></img>
                    <p className='text-center font-glacialBold text-blac text-xl mt-2 '>Bed Room</p>
                </div>
            </div>
        </div>
    )
}

export default Gallery;