import React from 'react'
import passkey from '../assets/passkey.png'
import tool from '../assets/tool.png'
import print from '../assets/fingerprint.png'
import garden from '../assets/kindergarden.png'

import parking from '../assets/parking.png'
import bag from '../assets/bag.png'
import medical from '../assets/patient.png'
import gym from '../assets/center.png'
//#006CC0

const Amentities = () => {
    return (
        <div className='my-20'>
            <p className='text-xl ml-8 text-gray-600'>AMENTITIES GALLERY</p>
            <p className='text-4xl font-bold text-blue-700 mt-4 mb-16 ml-8'>Building <span className='text-gray-600'><i>Amentities</i></span></p>

            <div className='flex flex-row gap-8 mx-8'>
                <div className=' flex-1 bg-blue-200 pb-8 rounded'>
                    <div className='flex justify-end '>
                        <img src={passkey} className='mr-6 mt-6 w-10 '></img>
                    </div>
                    <p className='ml-8 mt-8'>AMENTITIES</p>
                    <p className='ml-8 text-2xl font-semibold my-3'>Private Security</p>
                    <p className='ml-8'>Windows, skylights, vents, and glass portions of doors helps.</p>
                </div>

                <div className=' flex-1 bg-black pb-8 rounded'>
                    <div className='flex justify-end '>
                        <img src={tool} className='mr-6 mt-6 w-10 '></img>
                    </div>
                    <p className='ml-8 mt-8 text-white'>AMENTITIES</p>
                    <p className='ml-8 text-2xl text-white font-semibold my-3'>Private Security</p>
                    <p className='ml-8 text-white'>Windows, skylights, vents, and glass portions of doors helps.</p>
                </div>

                <div className=' flex-1 bg-blue-200 pb-8 rounded'>
                    <div className='flex justify-end '>
                        <img src={print} className='mr-6 mt-6 w-10 '></img>
                    </div>
                    <p className='ml-8 mt-8'>AMENTITIES</p>
                    <p className='ml-8 text-2xl font-semibold my-3'>Private Security</p>
                    <p className='ml-8'>Windows, skylights, vents, and glass portions of doors helps.</p>
                </div>

                <div className=' flex-1 bg-black pb-8 rounded'>
                    <div className='flex justify-end '>
                        <img src={garden} className='mr-6 mt-6 w-10 '></img>
                    </div>
                    <p className='ml-8 mt-8 text-white'>AMENTITIES</p>
                    <p className='ml-8 text-white text-2xl font-semibold my-3'>Private Security</p>
                    <p className='ml-8 text-white'>Windows, skylights, vents, and glass portions of doors helps.</p>
                </div>

            </div>

            <div className='mt-8 flex flex-row gap-8 mx-8'>
                <div className=' flex-1 bg-black pb-8 rounded'>
                    <div className='flex justify-end '>
                        <img src={parking} className='mr-6 mt-6 w-10 '></img>
                    </div>
                    <p className='text-white ml-8 mt-8'>AMENTITIES</p>
                    <p className='text-white ml-8 text-2xl font-semibold my-3'>Private Security</p>
                    <p className='text-white ml-8'>Windows, skylights, vents, and glass portions of doors helps.</p>
                </div>

                <div className=' flex-1 bg-blue-200 pb-8 rounded'>
                    <div className='flex justify-end '>
                        <img src={gym} className='mr-6 mt-6 w-10 '></img>
                    </div>
                    <p className='ml-8 mt-8'>AMENTITIES</p>
                    <p className='ml-8 text-2xl font-semibold my-3'>Private Security</p>
                    <p className='ml-8'>Windows, skylights, vents, and glass portions of doors helps.</p>
                </div>

                <div className=' flex-1 bg-black pb-8 rounded'>
                    <div className='flex justify-end '>
                        <img src={bag} className='mr-6 mt-6 w-10 '></img>
                    </div>
                    <p className='text-white ml-8 mt-8'>AMENTITIES</p>
                    <p className='text-white ml-8 text-2xl font-semibold my-3'>Private Security</p>
                    <p className='text-white ml-8'>Windows, skylights, vents, and glass portions of doors helps.</p>
                </div>

                <div className=' flex-1 bg-blue-200 pb-8 rounded'>
                    <div className='flex justify-end '>
                        <img src={medical} className='mr-6 mt-6 w-10 '></img>
                    </div>
                    <p className='ml-8 mt-8 '>AMENTITIES</p>
                    <p className='ml-8 text-2xl font-semibold my-3'>Private Security</p>
                    <p className='ml-8'>Windows, skylights, vents, and glass portions of doors helps.</p>
                </div>

            </div>
        </div>
    )
}

export default Amentities