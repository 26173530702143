import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from './../assets/ss1.png'
import facebook from './asset/facebook.png'
import instagram from './asset/instagram.png'
import youtube from './asset/youtube.png'
import mail from './asset/email.png'
import mobile from './asset/phone.png'
import location from './asset/location.png'

const Footer = () => {
    const [property, setProperty] = useState(false)

    return (
        <div className='bg-gray-300 w-full sm:w-full'>
            <div className='lg:flex lg:flex-row lg:justify-between px-12  w-full'>
                <div className='mt-8 w-full flex  flex-col md:w-auto md:justify-start md:items-start'>
                    {/* <img src={logo} className='w-48 -mt-8 -mb-6'></img> */}
                    <div className='flex flex-row gap-4 mb-2  w-8/12 justify-start md:w-auto'>
                        <img src={location} className='w-12 h-14 mt-1 md:w-7 md:h-8'></img>
                        <p className='lg:font-glacialBold text-2xl  gap-4 md:text-base'>Suma Shilp Ltd. ,<br></br> 93/5A, Erandwane, Pune : 411004</p>
                    </div>
                    <div className='flex flex-row gap-4 mb-2  justify-start w-8/12   md:w-auto'>
                        <img src={mobile} className='w-12 h-12 mt-1 md:w-6 md:h-6'></img>
                        <p className='lg:font-glacialBold mt-4 text-2xl  gap-4 md:mt-0 md:text-base'>020-25671312 | 020-25678427</p>
                    </div>
                    <div className='flex flex-row gap-4  justify-start w-8/12  md:w-auto'>
                        <img src={mail} className='w-12 h-12 mt-1 md:w-6 md:h-6'></img>
                        <p className='lg:font-glacialBold mt-4 text-2xl  gap-4 md:mt-0 md:text-base'>sales@sumashilp.com</p>
                    </div>

                    <div className='flex flex-row gap-4 mt-4 ml-10'>
                        <a target="_blank" href='https://www.facebook.com/profile.php?id=61564201380566&mibextid=LQQJ4d'><img src={facebook} className='w-12 h-12 md:w-8 md:h-8'></img></a>
                        <a target="_blank" href='https://www.instagram.com/sumashilpltd?igsh=MWVqNWw0M2JtM3l4ag=='><img src={instagram} className='w-12 h-12 md:w-8 md:h-8'></img></a>
                        <a target="_blank" href=''><img src={youtube} className='w-12 h-12 md:w-8 md:h-8'></img></a>
                    </div>
                </div>

                <div className='mt-8'>
                    <p className=' font-glacialBold mb-4 text-2xl  text-blac font-semibold md:text-xl'>Quick Links</p>
                    <Link to='/'><p className='mb-1 text-blac  font-glacialBold hover:text-blue-600 text-2xl md:text-base'>Home</p></Link>
                    <Link to='/westend'><p className='mb-1 text-blac  font-glacialBold hover:text-blue-600 text-2xl md:text-base'>Westend</p></Link>
                    <button onClick={() => { setProperty(!property) }} className='font-glacialBold mb-2 text-blac hover:text-blue-600  text-2xl md:text-base'>Projects</button>

                    {property && <div className='ml-4'>
                        <Link to='/residentianComp'><p className='mb-1 font-glacialBold text-gray-700 hover:text-gray-500  text-2xl md:text-base'>Ongoing</p></Link>
                        <Link to='/completed'><p className='mb-1 font-glacialBold text-gray-700 hover:text-gray-500  text-2xl md:text-base'>Completed</p></Link>
                    </div>}

                    <Link to='/gallery'><p className='hover:text-blue-600 mb-1 text-blac  font-glacialBold  text-2xl md:text-base'>Gallery</p></Link>
                    <Link to='/media'><p className='hover:text-blue-600 mb-1 text-blac  font-glacialBold  text-2xl md:text-base'>Media</p></Link>

                    <Link to='/opportunities'><p className='hover:text-blue-600 mb-1 text-blac  font-glacialBold  text-2xl md:text-base'>Opportunities</p></Link>
                    <Link to='/career'><p className='hover:text-blue-600 mb-1 text-blac  font-glacialBold  text-2xl md:text-base'>Careers</p></Link>
                    <Link to='/contact'><p className='hover:text-blue-600 mb-1  text-blac  font-glacialBold  text-2xl md:text-base'>Contact</p></Link>
                </div>
            </div>

            <div className='flex flex-row justify-end mt-4 gap-4 mx-52'>
                <Link to='/disclaimer'><p className='hover:text-blue-600 font-glacialBold  text-2xl md:text-base'>Disclaimer</p></Link>
                <Link to='/policy'><p className='hover:text-blue-600 font-glacialBold  text-2xl md:text-base'>Privacy Policy</p></Link>
            </div>
            <hr class="border-t-1 border-black "></hr>
            <p className='font-glacialBold text-center mt-2 pb-4 text-gray-600 text-xl md:text-base'>Suma Shilp Ltd. &copy; 2024.  All rights reserved.</p>
        </div>
    )
}

export default Footer;