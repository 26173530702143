import React from 'react'

import mail from '../footer/asset/email.png'
import mobile from '../footer/asset/phone.png'
import location from '../footer/asset/location.png'

import facebook from '../footer/asset/facebook.png'
import instagram from '../footer/asset/instagram.png'
import youtube from '../footer/asset/youtube.png'

const Contact = () => {
  const hndSbt = (e) => {
    e.preventDefault()
  }

  return (
    <div className=''>
      <div className='flex flex-col justify-center gap-20 mx-8 mb-28 mt-40 md:flex-row'>
        <div className='w-full md:flex-1'>
          <form onSubmit={hndSbt} className='flex flex-col'>
            <input placeholder='Name' className='bg-blue-100 h-12 text-center mb-4 rounded-md'></input>
            <input placeholder='Email' className='bg-blue-100 h-12 text-center mb-4 rounded-md'></input>
            <input placeholder='Company' className='bg-blue-100 h-12 text-center mb-4 rounded-md'></input>
            <textarea placeholder='Message' className='bg-blue-100 h-20 rounded-md text-center'></textarea>
            <button type='submit' className=' font-glacialBold mt-8 rounded-md h-12 bg-black2 hover:shadow-xl text-white'>Submit</button>
          </form>
        </div>

        <div className='font-glacialBold w-full md:flex-1 md:bg-white'>
          <p className='flex items-center gap-4 mb-4 font-semibold text-center md:text-left'>
            <img src={mobile} className='w-8' alt="Mobile icon" />
            020-25671312 | 020-25678427
          </p>
          <p className='flex items-center gap-4 mb-4 font-semibold text-center md:text-left'>
            <img src={mail} className='w-8' alt="Mail icon" />
            sales@sumashilp.com
          </p>
          <p className='flex items-center gap-4 font-semibold  md:text-left'>
            <img src={location} className='w-8' alt="Location icon" />
            Suma Shilp Ltd., 93/5A, Erandwane, Pune: 411004
          </p>

          <p className='mt-16 font-glacialBold text-xl md:text-left md:text-lg'>
            Please don’t hesitate to reach out.
          </p>

          <p className='mt-4 font- text-xl md:text-base text-left'>
            Office Hours:
          </p>
          <p className='font-glacialBold text-xl md:text-base text-left'>
            Monday-Saturday | 10:00 AM TO 6:00 PM
          </p>

          <div className='flex flex-row gap-4 mt-7 justify-start md:justify-start md:mt-4'>
            <a target="_blank" href='https://www.facebook.com/profile.php?id=61564201380566&mibextid=LQQJ4d' rel="noopener noreferrer">
              <img src={facebook} className='w-12 md:w-7' alt="Facebook" />
            </a>
            <a target="_blank" href='https://www.instagram.com/sumashilpltd?igsh=MWVqNWw0M2JtM3l4ag==' rel="noopener noreferrer">
              <img src={instagram} className='w-12 md:w-7' alt="Instagram" />
            </a>
            <img src={youtube} className='w-12 md:w-7' alt="YouTube" />
          </div>
        </div>


      </div>
    </div>
  )
}

export default Contact