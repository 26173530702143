import React, { useEffect } from 'react';
import 'tailwindcss/tailwind.css';

export default function Disclaimer() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="container mt-4">
      <section className=" p-4 rounded-lg shadow-lg">
        <h1 className="mb-4 text-black2 text-center text-2xl font-glacialBold font-bold">Disclaimer</h1>
        <div className="bg-white p-4 rounded-lg">
          <p className="text-lg font-glacialBold text-blac mb-4 ">Thank you for visiting Suma Shilp Ltd. website.</p>

          <div className="disclaimer-content space-y-4">
            <p className='text-blac font-glacialBold text-lg text-justify'>Specifications and amenities mentioned on this website are only representational and informative. Information, images, visuals, drawings plans or sketches shown on this website are only an architect's impression and indicate the envisaged developments. The same are subject to approvals from local authorities.</p>

            <p className='text-blac font-glacialBold text-lg text-justify'>Suma Shilp Ltd and its associated companies reserve the right to make additions, deletions, alterations, or amendments to this Information, images, and visuals, drawings plans, or sketches as and when it deems fit and proper, without any prior notice.</p>
            <p className='text-blac font-glacialBold text-lg text-justify'>No representation or warranty is made or intended as to the accuracy or completeness of information and no commitments are being given under this website as to its suitability or adequacy for any purpose or otherwise howsoever.</p>

            <p className='text-blac font-glacialBold text-lg text-justify'>All dimensions mentioned in the drawings may vary/differ due to construction contingencies and site conditions. Actual product/development and any other aspect may differ from what is portrayed herein. All layouts, plans, specifications, dimensions, designs, measurements, and locations are indicative and not to scale and are subject to change as may be decided by the company or competent authority. Revision, alteration, modification, addition, deletion, substitution or recast, if any, may be necessary during construction.</p>

            <p className='text-blac font-glacialBold text-lg text-justify'>The interior designing used in the flat / unit plan and images are only for representation purposes. The pictures of the proposed Residential Flat / Unit and all furniture, fixtures, items, electronic goods, amenities, landscaping, accessories etc. specified therein are only to showcase the Residential Flat / Unit and the Developer is not liable/required to provide any furniture, items, electronic goods amenities, accessories etc. as displayed in the pictures.</p>

            <p className='text-blac font-glacialBold text-lg text-justify'>The views shown in the picture may vary over some time and do not guarantee the same. All intending purchaser/s are bound to inspect all plans and approvals and visit the project site and apprise themselves of all plans and approvals and other relevant information and nothing in this website is intended to substitute to the intending purchaser the actual plans and approvals obtained from time to time. This website is merely conceptual and is not a legal document. It cannot be treated as a part of the final purchase agreement/s that may be executed from time to time. The Developer reserves the right to change, amend, modify the contents of the brochure and architectural specifications during development stages.</p>

            <p className='text-blac font-glacialBold text-lg text-justify'>Neither SSL nor any of their affiliates, nor their respective officers, employees, or agents, make any representation or warranty, express or implied, about the accuracy or completeness of the information contained in this document or any oral information provided in connection herewith, or any data it generates and accept no responsibility, obligation or liability (whether direct or indirect, in contract, tort or otherwise) about any of such information. The Developer / Management holds no responsibility for its accuracy and shall not be liable to any intending purchaser or anyone for the changes/alterations/improvements so made.</p>
          </div>
        </div>
      </section>
    </div>
  );
}
