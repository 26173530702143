import React from 'react';
import classes from './Two.module.css'
import ig1 from './asset/WestendCentre3.JPG'
import ig2 from './asset/Sankul2.JPG'
import ig3 from './asset/SumaHouse.JPG'

const One = () => {


    return (
        <>


            <div className={classes.a}>

                <div className={classes.b}>



                    <p className='text-3xl font-bold text-navy2 mb-3 font-proximal'> Ongoing Projects</p>

                    <br />
                    <hr className={classes.hrc} />

                    <div className={classes.c}>



                        <div className={classes.c1}>
                            <div className={classes.c11}>
                                <img src={ig1} className={classes.imgc}></img>
                            </div>
                            <div className={classes.c12}>
                                <p className='text-2xl text-navy2 mb-3 font-proximal'> SUMA CENTER</p>
                                <p className='text-xl  text-navy2 mb-3 font-proximal'> NAVI PETH</p>
                            </div>

                        </div>




                        <div className={classes.c2}>

                            <p className='text-1.5xl font-proximal'> Parichay by Belvalkar is a fresh take on luxury living in the classic neighborhood of Navi Peth. Offering 3 & 3.5 BHK homes, it combines the ease of modern living with the charm of old Pune. </p>

                        </div>


                        <div className={classes.c3}>

                            <div className={classes.btn}> LEARN MORE </div>

                        </div>


                    </div>


                    <hr className={classes.hrc} />

                    <div className={classes.c}>



                        <div className={classes.c1}>
                            <div className={classes.c11}>
                                <img src={ig1} className={classes.imgc}></img>
                            </div>
                            <div className={classes.c12}>
                                <p className='text-2xl text-navy2 mb-3 font-proximal'> SUMA CENTER</p>
                                <p className='text-1xl text-navy2 mb-3 font-proximal'> NAVI PETH</p>
                            </div>

                        </div>




                        <div className={classes.c2}>

                            <p className='text-1.5xl font-proximal'> Parichay by Belvalkar is a fresh take on luxury living in the classic neighborhood of Navi Peth. Offering 3 & 3.5 BHK homes, it combines the ease of modern living with the charm of old Pune. </p>

                        </div>


                        <div className={classes.c3}>

                            <div className={classes.btn}> LEARN MORE </div>

                        </div>


                    </div>

                    <hr className={classes.hrc} />



                    <div className={classes.c}>



                        <div className={classes.c1}>
                            <div className={classes.c11}>
                                <img src={ig1} className={classes.imgc}></img>
                            </div>
                            <div className={classes.c12}>
                                <p className='text-2xl  text-navy2 mb-3 font-proximal'> SUMA CENTER</p>
                                <p className='text-1xl text-navy2 mb-3 font-proximal'> NAVI PETH</p>
                            </div>

                        </div>




                        <div className={classes.c2}>

                            <p className='text-1.5xl font-proximal'> Parichay by Belvalkar is a fresh take on luxury living in the classic neighborhood of Navi Peth. Offering 3 & 3.5 BHK homes, it combines the ease of modern living with the charm of old Pune. </p>

                        </div>


                        <div className={classes.c3}>

                            <div className={classes.btn}> LEARN MORE </div>

                        </div>


                    </div>

                    <hr className={classes.hrc} />


                </div>

            </div>

        </>
    )

}


export default One