import React, { useState } from "react";
import { Link } from "react-router-dom";

import modibaug1 from "./assets/Modibaug.jpg";
import "./Hero.css";
import modi2 from "./assets/7 Modi Baug Presentation_2.jpg";
import modi3 from "./assets/7 Modi Baug Presentation_3.jpg";
import Westend from './assets/WestEndCrown1.jpg'
import { Button } from "react-scroll";
import tick from './assets/checklist.png'

import LandSlide from "./LandSlide";

const Hero = () => {
  const [count, setCount] = useState(1);

  const clkHnd = (num) => {
    setCount(num);
  };

  return (
    <div className="my-12">
      <div className="flex flex-col justify-between gap-4 ml-12 mr-12 md:flex-row">
        <div className="w-full md:w-[45vw]">
          <div className="modiHero">
            {count == 1 && (
              <img src={Westend} className="w-full h-[50vh] md:w-[50vw] md:h-[80vh]"></img>
            )}
            {count == 2 && (
              <img src={Westend} className="w-full h-[50vh] md:w-[50vw] md:h-[80vh]"></img>
            )}
            {count == 3 && (
              <img src={Westend} className="w-full h-[50vh] md:w-[50vw] md:h-[80vh]"></img>
            )}
          </div>
          <div className="flex flex-row justify-center gap-8 mt-4">
            <button
              onClick={() => {
                clkHnd(1);
              }}
              className="w-20 h-20"
            >
              <img src={Westend}></img>
            </button>
            {/* <button
              onClick={() => {
                clkHnd(2);
              }}
              className="w-20 h-20"
            >
              <img src={modi2}></img>
            </button> */}

          </div>
        </div>

        <div className="w-full md:w-[45vw]">
          <p className="text-5xl mt-2 mb-1 font-semibold text-black2 font-glacialBold text-center">
            Westend Icon
          </p>
          <p className="text-center mb-4 text-blac text-lg  font-glacialBold">
            Located in the prime, central area of Aundh, Pune
          </p>
          <hr className="mb-2"></hr>

          <p className="text-center text-blac text-xl  font-glacialBold mb-2">
            status : <b>Ready For Fitouts</b>
          </p>
          <p className="text-center text-blac text-xl  font-glacialBold">
            30,000 sf. Average floor plate.
          </p>
          <p className="meri mt-4 mx-12  text-blac text-justify text-xl font-glacialBold md:text-lg">The Westend Icon, a joint venture by Chitrali Properties Pvt Ltd and the Blackstone Group, is a part of  mixed-use development spanning 0.36 million sq.ft. Located off Parihar Chowk, Aundh, it caters to the corporate requirements with customizable floors layouts  and world-class amenities. Located conveniently near IT hubs like Baner & Hinjawadi, it offers a direct access to the Westend Mall. Supported by exclusive amenities and diverse retail, F&B and entertainment options nearby, the Westend Icon ensures well-equipped & productive workspaces.</p>
          <Link to="/contact">
            <button className="bg-navy meri text-white mx-auto block mt-4 w-4/5 h-12 rounded">
              Contact Us
            </button>
          </Link>
        </div>
      </div>

      <p className="text-3xl mb-4 mt-12 ml-8 text-black2 font-semibold font-glacialBold">
        Key Project Highlights
      </p>
      <div className=" w-8/12 ml-8">
        <section className=" mt-8">
          <ul className="  pl-5  font-glacialBold text-blac text-xl md:text-lg">
            <li className='flex flex-row gap-4 font-glacialBold'>
              <img src={tick} className='w-5 h-5'></img>
              High performance façade design ensuring substantial energy savings.
            </li>
            <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>RCC framed structure</li>
            <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Floor system flat slab / PT slab</li>
            <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Oﬃce Leasable Area: ~360,000 sf  </li>
            <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Structure: 2B + G + 15 ﬂoors  </li>
            <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Average ﬂoor plate: ~30,000 sf</li>
            <li className='flex flex-row gap-4 font-glacialBold'><img src={tick} className='w-5 h-5'></img>Usage: Oﬃce IT/ ITeS Space /Commercial  </li>
          </ul>
        </section>
      </div>

      <div>
        <p className="ml-8 mb-4 mt-8  font-glacialBold text-black2 text-3xl font-semibold">Green Building Features</p>

        <div className="flex flex-row gap-12 ml-8">
          {/* left */}
          <div>
            <div className="mb-8">
              <p className=" font-glacialBold text-2xl text-blac">Best Waste Management Practices:</p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Colour-coded bins for segregation of waste</p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Organic Waste Composting Machine to manage </p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>organic waste in-house</p>
            </div>




            <div className="mb-8">
              <p className=" font-glacialBold text-blac text-2xl">Water Conservation & Management</p>

              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>MBBR based STP technology for water recycling</p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Water Conservation through Rain Water Harvesting<br></br> System </p>


            </div>

            <div className="mb-8">
              <p className=" font-glacialBold text-blac text-2xl">Universal Design</p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Provisions for specially-abled people.</p>
            </div>

          </div>

          {/* right */}
          <div>
            <div className="mb-8">
              <p className=" font-glacialBold text-blac text-2xl">Oﬃce Spaces designed for the specially abled people:</p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Accessible Ramps</p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Restrooms designed for the specially abled people</p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Elevators with audio-visual aid and disabled-friendly features</p>
            </div>

            <div className="mb-8">
              <p className=" font-glacialBold text-blac text-2xl">Energy Eﬃciency & Management</p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>High-performance double glazed facade</p>
              <p className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Energy eﬃcient building with substantial energy savings</p>
            </div>





          </div>
        </div>


      </div>


      <div>
        <p className="ml-8 mt-8 text-3xl text-black2 font-semibold font-glacialBold mb-4">Technical Features</p>

        <div className="flex flex-row gap-12 mx-8">
          {/* left */}
          <div>
            <ul className="text-xl md:text-lg">
              <li className="flex flex-row gap-4  text-blac font-glacialBold"><img src={tick} className='w-5 h-5'></img>Exclusive grand double height entrance  lobby

              </li>
              <li className="flex flex-row gap-4  text-blac font-glacialBold"><img src={tick} className='w-5 h-5'></img>High-performance façade design<br></br>
              </li>
              <li className="flex flex-row gap-4  text-blac font-glacialBold"><img src={tick} className='w-5 h-5'></img>Integrated Building  Management system</li>
              <li className="flex flex-row gap-4  text-blac font-glacialBold"><img src={tick} className='w-5 h-5'></img>Chiller-based air-conditioning system</li>

              <li className="flex flex-row gap-4  text-blac font-glacialBold"><img src={tick} className='w-5 h-5'></img>Ample parking</li>
              <li className="flex flex-row gap-4  text-blac font-glacialBold"><img src={tick} className='w-5 h-5'></img>RCC framed structure</li>
            </ul>

          </div>

          {/* right */}
          <div>
            <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>High-Speed Passenger elevators with  Destination Control system </li>
            <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Security systems - CCTV surveillance  system</li>
            <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Elegantly conceptualized landscaped areas</li>
            <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Floor system ﬂat slab</li>
            <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Modern ﬁre detection  & suppression system</li>
            <li className="flex flex-row gap-4  text-blac text-xl font-glacialBold md:text-lg"><img src={tick} className='w-5 h-5'></img>Grand ﬂoor to ﬂoor height</li>



          </div>
        </div>
      </div>



      {/* <div>
        <LandSlide></LandSlide>
      </div> */}
    </div>
  );
};

export default Hero;
