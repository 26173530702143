import React, { useEffect } from 'react'
import './Aboutpage.css'
import { Link } from 'react-router-dom'


import Why from '../components/about/Why'
import Story from '../components/about/Story'
import Vision from '../components/about/Vision'
import Mission from '../components/about/Mission'
import Track from '../components/about/Track'
import Core from '../components/about/Core'
import AboutSlider from '../components/about/AboutSlider'
import Core2 from '../components/about/Core2'
import Qualitiy from '../components/home/Qualitiy'

import logo from '../components/nav/logo.png'
import img1 from '../components/assets/5.jpeg'

import coming from './assets/coming2.jpg'
import Layers from '../components/about/Layers'

const Aboutpage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            {/* <p className='text-center font-glacialBold text-2xl font-semibold mb-20'>Media</p> */}
            {/* <p className='text-center text-6xl mb-40 text-blue-900 font-bold'>Unveiling soon...</p> */}

            <div className='mb-80 mt-32'>
                <Layers></Layers>
            </div>

            {/* <img src={coming} className='w-full'></img> */}
            {/* <p className='mt-32 ml-16 text-blac   font-glacialBold font-semibold'>About Us</p>

            <div className='flex flex-row mt-20 justify-between mx-8 '>
                <div className=' w-5/12 mr-4 rounded mt-4'>
                    <AboutSlider></AboutSlider>
                </div>
                <div className=' w-6/12 '>
                    <p className='font-glacialBold ml-8 text-5xl text-blac font-semibold'>Suma Shilp Ltd.</p>
                    <p className=' font-glacialBold text-xl text-blac my-8 ml-8 text-left'>Suma Shilp Ltd. specializes in facilitating connections for businesses within thriving commercial ecosystems, fostering environments conducive to their growth. Beyond an extensive portfolio of commercial real estate services, Suma Shilp Ltd. has successfully delivered some of Pune's most prestigious real estate projects, significantly enhancing the quality of life for its residents.</p>
                    <p className=' font-glacialBold text-xl text-blac ml-8'>As a distinguished construction and real estate firm, the group is committed to providing seamless and comprehensive services. With a reputation as a leading end-to-end provider in both commercial and residential real estate sectors, Suma Shilp Ltd. leverages over five decades of expertise, solidifying its pivotal role in Pune’s dynamic real estate landscape.</p>
                    <Link to='/contact'><button className='bg-navy font-proximal text-white mt-6 ml-8 px-6 py-2 rounded'>Let's Discuss</button></Link>
                </div>

            </div> */}

            {/* <Core2></Core2>
           <Core></Core> 
             <Track></Track>*/}

            {/* <Mission></Mission>
            <Story></Story> */}
            {/* <Vision></Vision> 

            <Why></Why>
            {/* <Qualitiy></Qualitiy> */}

        </div>
    )
}

export default Aboutpage;