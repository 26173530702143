import React, { useState } from 'react'
import Drop from './Drop'
import { Link } from 'react-router-dom'
import AboutSlider from '../about/AboutSlider'


import Popup from './popup/Popup'

//import img1 from '../assets/story.webp'

import img1 from './../../components/assets/MODIBAUG.jpg'

const Story = () => {
    const [popOpen, setPopOpen] = useState(false)

    const onClose = () => {
        setPopOpen(false)
    }

    return (
        <div className=' w-full '>

            <p className='story text-4xl text-black2 font-semibold ml-16 mt-24   font-glacialBold'>Our Story</p>
            <div className='w-full flex flex-col justify-center gap-16 mt-4 md:flex-row'>

                <div className='w-full mt-5 md:w-5/12'>
                    <AboutSlider></AboutSlider>
                </div>

                <div className='w-full  md:w-5/12'>

                    <p className='text-2xl mx-4 mt-4 mb-2 text-justify text-md  text-blac  font-glacialBold md:mx-0 md:text-base'>Established in 1969, Suma Shilp Ltd has been at the forefront of real estate development and construction. The company's inception traces back to a partnership firm named Kanetkar Associates, founded by Mr. Anand Naralkar and Mr. Narayanrao V. Kanetkar. Over the more than 5 decades, the firm has executed some of Pune's most prestigious projects, many of which have become city landmarks and significantly impacted the lives of Puneites.</p>
                    <p className='text-2xl mx-4 text-md text-justify text-blac  font-glacialBold md:mx-0 md:text-base'>In November 1969, Mr. Naralkar also initiated a family-owned partnership firm, Vastushilp, which later evolved into Suma Engineering Private Limited (now Suma Shilp Limited).
                        Mr. Pramod Naralkar, a civil engineering graduate from IIT Mumbai and a postgraduate in Structural Engineering and Business Administration from Duke University, USA, joined forces with his father in 1983.
                        Under the leadership of Mr. Pramod Naralkar, Suma Shilp Limited has grown steadily and is now recognized as one of Pune's most reputable construction companies. Most of the business comes from client references, highlighting the company’s positive image and goodwill.
                    </p>



                    <p className='ml-4 font-glacialBold text-2xl font-semibold mt-4 text-black2 md:ml-0'>Our Aspiration</p>
                    <p className='text-2xl text-justify font-glacialBold text-blac mx-4 mt-2 md:text-base md:mx-0'>Suma Shilp Ltd is dedicated to advancing growth within the realm of commercial and residential real estate.
                        <button className='text-blue-900 ml-4 font-semibold text-xl md:text-sm' onClick={() => { setPopOpen(true) }} >Read More</button></p>

                </div>

            </div>


            {popOpen && <Popup onClose={onClose}></Popup>}

        </div>
    )
}

export default Story