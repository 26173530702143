import React, { useEffect } from 'react'
import Hero from './Hero';
import Gallery from './Gallery';
import Map from './Map';
const Modibaug = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <Hero></Hero>
            <Gallery></Gallery>
            <div>
                <Map></Map>
            </div>
        </div>
    )
}

export default Modibaug;